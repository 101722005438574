import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useContext } from "react";
import {AuthContext} from "../Context/ProvideAuth";
import { useNavigate } from "react-router";
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const Home = () => {
  const navigate = useNavigate();
  const {auth} = useContext(AuthContext);
  useEffect(()=>{
    if(auth?.role === 'admin'){
      navigate('/admin/shipments')
    }else if(auth?.role === 'donee'){
      navigate('/donee/shipments')
    }else{
      navigate('/login')
    }
  }, [auth.role, navigate]);
  return (
    <>
    <Header activeMenu="Home" />
    <Footer />
    </>
  )
}

export default Home

