import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../Components/Header';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../../../Components/Footer';
import TableLoader from '../../../Components/TableLoader';
import AssignContactPopup from './AssignContactPopup';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axiosInstance';
//import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StatusMenu from './StatusMenu';
import ShipmentFilterPopup from './ShipmentFilterPopup';
import { Badge, CloseButton } from 'react-bootstrap';

import $ from 'jquery';
import 'datatables.net';
import "datatables.net-dt/css/jquery.dataTables.css";

const AdminCompletedShipments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, updateFilterData] = useState({
    country: [],
    donee: [],
    tag: [],
  });
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [viewContactRow, setViewContactRow] = useState([]);
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const tableRef = useRef(null);
  
  const handleSyncData = () => {
    setTimeout(function(){
      toast.success("Data sync successfully!");
    },1000);
  }
  const viewShipmentFilter = () => {
    setShowFilter(true);  
    setShow(false);
  }
  const handleShipmentFilter = (value) => {
    updateFilterData(value);
    setShowFilter(false);
  };
  const handleShipmentCancelFilter = (value) => {
    setShowFilter(false);
    
  };
  const handleAssignContactCallback = (value) => {
    setShow(false);
    console.log("value from child", value);
  };
  const handleAssignContactCancelCallback = (value) => {
    setShow(false);
  };
  const removeFilterData = (index, type) => {
    if(type === 'country'){
      const filter_arr = filterData.country;
      filter_arr.splice(index, 1);
      updateFilterData({
        country: filter_arr,
        donee: filterData.donee,
        tag: filterData.tag,
      });
    }
    if(type === 'donee'){
      const filter_arr = filterData.donee;
      filter_arr.splice(index, 1);
      updateFilterData({
        country: filterData.country,
        donee: filter_arr,
        tag: filterData.tag,
      });
    }
    if(type === 'tag'){
      const filter_arr = filterData.tag;
      filter_arr.splice(index, 1);
      updateFilterData({
        country: filterData.country,
        donee: filterData.donee,
        tag: filter_arr,
      });
    }
  }
  const viewShipmentDetails = row => {
    if(row?.id){
      var pageData = {
        filterData:filterData
      }
      navigate('/admin/shipments/completed/view/'+row?.id,{state:{pageData:pageData}})
    }else{
      
    }
  }
  
  useEffect(() => {
    // Initialize DataTable
    const dataTable = $(tableRef.current).DataTable({
      autoWidth: false,
        destroy: true,
        processing: true,
        serverSide: true,
        "filter": true,
        lengthChange: true,
        scrollX: true,
        responsive: true,
        stateSave: true,
        paging: true,
        "lengthMenu": [ 10, 15, 50, 75, 100 ],
        regex: false,
        smart: false,
      ajax: {
        url: "/dummydata/pending_shipments.txt",
        type: "GET",
        data: {
          filterData: filterData
        },
        complete: function() {
            $('.row-delete').on('click', function() {
                //deleters($(this).attr('data-id'), 'blogs', 'delete');
            });
        }
    },
    language: {
      paginate: {
          previous: '<i class="fas fa-chevron-left"></i>',
          next: '<i class="fas fa-chevron-right"></i>'
      }
    },
    "columns": [ {
        "data": "shipment"
    },{
      "data": "ship_date"
    },{
      "data": null
    },{
      "data": "donee"
    },{
        "data": "country"
    },{
      data: null,
    },{
      data: null,
    },{
      data: null,
    },{
      data: 'discrepancy',
    }],
  "columnDefs": [
    {
      "targets": 0,
      "render": function ( data, type, row ) {
        return row['shipment'] + ' <br />' + row['ship_date'];
      }
    },
    {
      "targets": 2,
      "render": function ( data, type, row ) {
          return row['first_name'] + ' ' + row['last_name'] + '<br /> ' + row['email'];
      }
    },
    {
        "targets": 5,
        "render": function ( data, type, row ) {
            return '<div class="font_small"><a class="t_border_btn">Tag 1</a> <a class="t_border_btn" href="#">+ 1</a></div>';
        }
    },
    {
      "targets": 6,
      "render": function ( data, type, row ) {
          return `<div class='font_small'><a class="t_border_btn" href="#">Completed</a>
          <div>27 Oct 2023</div>
          </div>`;
      }
    },
    {
      "targets": 7,
      "render": function ( data, type, row ) {
          return `<div class='font_small'><a class="t_border_btn" href="#">Pending</a>
          <div>27 Oct 2023</div>
          </div>`;
      }
    },
    {
      "targets": 8,
      "render": function ( data, type, row ) {
        return `<span class="color_red">Yes</span>`;
      }
    }
    ],
    order: [
      [0, "desc"]
    ]
      // DataTable options go here
    });
    $('.table_search .dataTables_filter').remove();
    $('.dataTables_filter').insertAfter('.table_search > img');
    $('.dataTables_filter input').addClass('form-control form-control-sm');
    $('.dataTables_filter .form-control').attr('placeholder', 'Search');
    $('.dataTables_length').insertAfter('.dataTables_info');
    $('.dataTables_filter select').addClass('form-select form-select-sm');
    $('.sorting .sort-icon').remove();
    dataTable.columns().iterator( 'column', function (ctx, idx) {
      $( dataTable.column(idx).header() ).append('<span class="sort-icon"/>');
    } );
    // Handle row click event
    $(tableRef.current).on('click', 'tbody tr', function () {
      const rowData = dataTable.row(this).data();
      rowData.password = '********';
      rowData.country = '+1 USA';
      viewShipmentDetails(rowData);
      // Perform actions with rowData as needed
    });

    // Cleanup on component unmount
    return () => {
      $(tableRef.current).off('click', 'tbody tr');
      dataTable.destroy();
    };
  }, [filterData]);
  return (
    <>
    <Header activeMenu="Shipments" />
    <main className="main-content">
    <section className="content_hdr_sec p-0">
        <div className="container-fluid">
          <div className="row mobile_2row justify-content-between align-items-center">
            <div className="col-md-auto">
                <StatusMenu activeMenu="completed" activeCount="5" pendingCount="8"/>
            </div>
            <div className="col-md-auto">
              <div className="cont_hdr_right">
                <div className="cont_hdr_item"><Link onClick={handleSyncData}><img src="/images/sync_icon.svg" alt='' /></Link></div>
                <div className="cont_hdr_item"><Link onClick={viewShipmentFilter} ><img src="/images/filter_icon.svg" alt='' /></Link></div>
                <div className="cont_hdr_item table_search">
                  <img className="" src="/images/search_icon.svg" alt='' />
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label></label></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid mb-3 custom-tags">
          {filterData.country.map(function(option, index) {
            return (
                <Badge bg="secondary" value={option.value} key={`country-key-`+option.value}>{option.label} <CloseButton onClick={(e) => removeFilterData(index, 'country')} className='btn-sm' /></Badge>
                )
            })}
            {filterData.donee.map(function(option, index) {
            return (
                <Badge bg="secondary" value={option.value} key={`donee-key-`+option.value}>{option.label} <CloseButton onClick={(e) => removeFilterData(index, 'donee')} /></Badge>
                )
            })}
            {filterData.tag.map(function(option, index) {
            return (
                <Badge bg="secondary" value={option.value} key={`tag-key-`+option.value}>{option.label} <CloseButton onClick={(e) => removeFilterData(index, 'tag')} /></Badge>
                )
            })}
        </div>
        <section className="data_table_main">
          <div className="container-fluid">
            <div className="data_table_wrap datatable-custom-design row-hover">
                <table id="myTable1" ref={tableRef} className="table table-borderless datatable_cmn" >
                  <thead>
                      <tr>
                          <th>Shipment</th>
                          <th>Shipment ETA</th>
                          <th>Contact</th>
                          <th>Donee</th>
                          <th>Country</th>
                          <th>Tags</th>
                          <th>Ack. of Delivery</th>
                          <th>Confirmation of goods</th>
                          <th>Discrepancy</th>
                      </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </table>
            </div>
          </div>
        </section> 
    </main>
    <Footer />
    <AssignContactPopup handleAssignContactCancelCallback={handleAssignContactCancelCallback} handleAssignContactCallback={handleAssignContactCallback} contactInfo={viewContactRow} showPopup={show} />
    <ShipmentFilterPopup filterData={filterData} handleShipmentCancelFilter={handleShipmentCancelFilter} handleShipmentFilter={handleShipmentFilter} showPopup={showFilter} />
    </>
  )
}

export default AdminCompletedShipments
