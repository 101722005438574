import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import {Routes, Route} from 'react-router-dom'
import AdminShipments from './Pages/Admin/Shipments';
import AdminActiveShipments from './Pages/Admin/Shipments/ActiveShipments';
import AdminContacts from './Pages/Admin/Contacts';
import Login from './Authentication/Login';
import Logout from './Authentication/Logout';
import ForgotPassword from './Authentication/ForgotPassword';
import Home from './Pages/Home';
import Unauthorized from './Pages/Unauthorized';
import Auth from './Pages/Auth';
import Missing from './Pages/Missing';
import { ProvideAuth } from './Context/ProvideAuth';
import ResetPassword from './Authentication/ResetPassword';
import AdminMedia from './Pages/Admin/Media';
import AdminDonee from './Pages/Admin/Donee';
import AdminCompletedShipments from './Pages/Admin/Shipments/CompletedShipments';
import AdminViewActiveShipment from './Pages/Admin/Shipments/ViewActiveShipments';
import AdminViewCompletedShipments from './Pages/Admin/Shipments/ViewCompletedShipments';
import DoneeActiveShipments from './Pages/Donee/Shipments/ActiveShipments';
import DoneeCompletedShipments from './Pages/Donee/Shipments/CompletedShipments';
import DoneeMedia from './Pages/Donee/Media';

function App() {
  return (
    <ProvideAuth>
      <Routes>
        <Route path = '/login' element={<Login/>}/>
        <Route path = '/logout' element={<Logout/>}/>
        <Route path = '/forgot-password' element={<ForgotPassword/>}/>
        <Route path = '/reset-password/:key' element={<ResetPassword/>}/>
        <Route path='/unauthorized' element={<Unauthorized/>}/>
        
        {/* {Protected Routes} */}
        <Route element={<Auth allowedRoles={['admin','donee']}/>}>
          <Route path='/' element={<Home/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/shipments' element = {<AdminShipments/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/shipments/active' element = {<AdminActiveShipments/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/shipments/completed' element = {<AdminCompletedShipments/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/shipments/view/:id' element = {<AdminViewActiveShipment/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/shipments/completed/view/:id' element = {<AdminViewCompletedShipments/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/contacts' element = {<AdminContacts/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/media' element = {<AdminMedia/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['admin']}/>}>
          <Route path ='/admin/donee' element = {<AdminDonee/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['donee']}/>}>
          <Route path ='/donee/shipments' element = {<DoneeActiveShipments/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['donee']}/>}>
          <Route path ='/donee/shipments/completed' element = {<DoneeCompletedShipments/>}/>
        </Route>
        <Route element={<Auth allowedRoles={['donee']}/>}>
          <Route path ='/donee/media' element = {<DoneeMedia/>}/>
        </Route>
        <Route path="*" element={<Missing />} />
        {/* </Route> */}
      </Routes>
    </ProvideAuth>
    
      
  );
}

export default App;
