import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form"
import Footer from '../../../Components/Footer';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import {
	Dropdown,
	DropdownIndicator
} from '../../../Components/SelectDropdownCustom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8}),
  menu: () => ({ maxHeight: 200, overflowY: 'auto' }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: 6,
    opacity: '0.8',
    left: 6,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '30px'
  }),
};
const AdminViewActiveShipment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState('');
  const [filterData, updateFilterData] = useState({
    country: [],
    donee: [],
    tag: [],
  });
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [isOpen, setIsOpen] =useState(false);
  const toggleOpen = () => {
    if(isOpen === true){
      setIsOpen(false);
    }else{
      setIsOpen(true);
    }
    
  };
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
        id: "",
        shipment_eta: "2023-10-23",
        notes: "",
        tag: "",
        sent_blank_dis_report: '',
        recevied_completed_dis_report: '',
        mark_as_complete: false,
        pro_farma_invoice: []
    },
  })
  const { 
    register: register2,
    watch: watch2,
    setValue: setValue2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    } = useForm(
    {
        defaultValues: {
          notes: "",
          shipment: "",
          contact_name: "",
          email: ""
        },
    }
  );
  const onSubmitSendNotification = (data) => {
    console.log(data);
    try {
      handleShow();
      }
      catch(err){
          if(!err?.response){
              //setError('no server response');
          }
          else {
              //setError('update failed')
          }
      }
    
  }
  const fetchUsers = async page => {
    setLoading(true);
    const response = await axios.get(`https://reqres.in/api/user/1`);
    //setData(response.data.data);
    var demodata = response.data.data
    demodata.shipment = '#61234';
    demodata.contact = 'Leo Thomas <br> welcome@xyz.com';
    demodata.ship_date = '12 Oct 2023';
    demodata.country = '+1 USA';
    demodata.donee = 'Donee 1';
    demodata.assign_contact = 'aa';
      demodata.mark_as_completed = 'aa';
    setData(demodata);
    setLoading(false);
  };
  const goBack = () =>{
    navigate('/admin/shipments/active',{state:{pageDataBack: location.state?.pageData}});
  }
  const onSubmit = (data) => {
    console.log(data);
    toast.success("Shipment Details Updated.");
    setTimeout(function(){
      goBack();
    },2000);
  }
  const handleUploadedFile = (event) => {
    const file = event.target.files[0];
    //console.log(file);
    //const urlImage = URL.createObjectURL(file);
    setPreview(file.name);
  };
  const setShipmentDate = (data) => {
    setValue('shipment_eta',data);
  }
  const handleSelectTag = (data) =>{
    setSelectedTag(data);
    setValue('tag',data);
  }
  const handleChangeTag = (selectedOptions) => {
    setSelectedTag(selectedOptions);
    setValue('tag',selectedOptions);
  };
  const handleRemoveTag = (optionToRemove) => {
    setSelectedTag(selectedTag.filter(option => option.value !== optionToRemove.value));
    setValue('tag',selectedTag.filter(option => option.value !== optionToRemove.value));
  };
  const customFilterOption = (option, inputValue) => {
    return option.data.label_old.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
  };
  const customOptionsTags = () => {
    return tagList.map(option => ({
      ...option,
      label_old:option.label, 
      label: (
        <div>
          <input
            type="checkbox"
            className='form-check-input'
            checked={selectedTag.some(selectedOption => selectedOption.value === option.value)}
            onChange={() => handleChangeTag(
              selectedTag.some(selectedOption => selectedOption.value === option.value)
              ? selectedTag.filter(selectedOption => selectedOption.value !== option.value)
              : [...selectedTag, option]
            )}
          />
           <span className='select-checkbox-label'> {option.label}</span>
        </div>
      )
    }));
  };
  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var tagArr = [
      {value: 1, label: "Tag 1"},
      {value: 2, label: "Tag 2"},
      {value: 3, label: "Tag 3"},
      {value: 4, label: "Tag 4"},
      {value: 5, label: "Tag 5"},
      {value: 6, label: "Tag 6"},
      {value: 7, label: "Tag 7"},
    ]
    setTagList(tagArr);
  }, []);
  useEffect(()=>{
    document.body.classList.add('bg_light');
    document.body.classList.add('body_admin');
  },[])
  return (
    <>
    <main className="main-content">
      <section className="content_hdr_sec">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
                <h1 className="page_head"><Link className="back_link" to="/admin/shipments/active" state={{
    pageDataBack: location.state?.pageData,
  }}><img src="/images/back_arrow2.svg" alt=""/></Link> View Shipment</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="view_shipment_sec">
        <div className="container-fluid">
          <div className="view_shipment_main">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="view_tab1-tab" data-bs-toggle="tab" data-bs-target="#view_tab1" type="button" role="tab" aria-controls="view_tab1" aria-selected="true">Shipment Details</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="view_tab2-tab" data-bs-toggle="tab" data-bs-target="#view_tab2" type="button" role="tab" aria-controls="view_tab2" aria-selected="false">Notification</button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="view_tab1" role="tabpanel" aria-labelledby="view_tab1-tab">
                <div className="view_shipment_body">
                  <form className="form_normal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" noValidate>
                    <div className="view_ship_cont">
                      <div className="row">
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Shipment ID <span className="req_star">*</span></label>
                              <input type="text"  readOnly className="form-control" value="#61234" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Ship Date <span className="req_star">*</span></label>
                              <input type="text" readOnly className="form-control" value="12 Oct 2023" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Country <span className="req_star">*</span></label>
                              <input type="text" readOnly className="form-control" value="USA" />
                          </div>
                          <div className="col-md-6 mb-3 custom-calender">
                              <label className="form-label">Shipment ETA </label>
                              <Controller
                                name="shipment_eta"
                                control={control}
                                defaultValue={null}
                                render={({ field }) => (
                                  <DatePicker
                                  className="form-control getdate"
                                  showIcon
                                  dateFormat="dd MMM yyyy"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    icon={<span class="input-group-text"><img src="/images/calendar_icon.svg" alt="" /></span>}
                                  />
                                )}
                              />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Donee <span className="req_star">*</span></label>
                              <input type="text" readOnly className="form-control" value="Lorem Ipsum" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Contact Email ID <span className="req_star">*</span></label>
                              <input type="email" readOnly className="form-control" value="welcome@xyz.com" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Tags <span className="req_star">*</span></label>
                              <Dropdown
                                isOpen={isOpen}
                                onClose={toggleOpen}
                                target={
                                  <Button
                                  className={'form-select basic-single single-custom-dropdown-multiple '+ ((errors.tag) ? ' error': '')}
                                    onClick={toggleOpen}
                                    active={isOpen}
                                  >
                                    {selectedTag ? `Select Tag` : 'Select Tag'} 
                                      </Button>
                                }
                              >
                                <Select
                                className={'basic-single react-select-container react-select-container-absolute react-select-container-absolute-col-6'}
                                  autoFocus
                                  backspaceRemovesValue={false}
                                  components={{ DropdownIndicator, IndicatorSeparator: null }}
                                  controlShouldRenderValue={false}
                                  hideSelectedOptions={false}
                                  isClearable={false}
                                  {...register("tag", {required: true})} 
                                  isMulti
                                  menuIsOpen
                                  styles={selectStyles}
                                  onChange={handleSelectTag}
                                  options={customOptionsTags()}
                                  filterOption={customFilterOption}
                                  placeholder="Select Tag"
                                  tabSelectsValue={false}
                                  value={selectedTag}
                                />
                              </Dropdown>
                              { !isOpen &&  <div className="mt-2 selected_tag_list"> {selectedTag.map((item, i) => (
                                <span className='m-1' key={i}>{item.label_old} <a onClick={()=> handleRemoveTag(item)} ><img src="/images/close_icon.svg" alt='' /></a></span>
                              ))}
                              </div>
                              }
                                {errors.tag && errors.tag.type === "required" && (
                                    <span className='input-error'>This field is required.</span>
                                )}
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Notes</label>
                              <textarea className="form-control" {...register("notes", {required: false})} placeholder="Enter your note..."></textarea>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label d-block">Sent Blank Distribution Report</label>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("sent_blank_dis_report")} className="form-check-input" type="radio"  value="yes" /><span className="radio_cir"></span> Yes</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("sent_blank_dis_report")} className="form-check-input" type="radio" value="no" /><span className="radio_cir"></span> No</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("sent_blank_dis_report")} className="form-check-input" type="radio" value="na" /><span className="radio_cir"></span> N/A</label>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Acknowledgement of Delivery</label>
                              <div className="selected_tag_list">
                                <span className="mt-0">Completed</span>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label d-block">Received Completed Distribution Report</label>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("recevied_completed_dis_report")} className="form-check-input" type="radio" value="yes" /><span className="radio_cir"></span> Yes</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("recevied_completed_dis_report")} className="form-check-input" type="radio" value="no" /><span className="radio_cir"></span> No</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("recevied_completed_dis_report")} className="form-check-input" type="radio" value="na" /><span className="radio_cir"></span> N/A</label>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Confirmation of Goods</label>
                              <div className="selected_tag_list">
                                <span className="mt-0">Pending</span>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-check-label check_custom">
                                <input type="checkbox" {...register("mark_as_complete", {required: false})} className="form-check-input" />
                                <span className="check_squire"><img src="/images/check_small.svg" width="12" height="12" alt='' /></span>
                                Mark Shipment as Complete
                              </label>
                          </div>
                          <div className="col-md-6 mb-3">
                            <div className="input_file_custom">
                              <input type="file" {...register("pro_farma_invoice", {required: false})} onChange={handleUploadedFile} />
                              <div className="inputtype_file_btn"><img src="/images/upload_icon.svg" alt="" /> Upload Pro-Forma Invoice</div>
                            </div>
                            <p className="file_name">{preview}</p>
                          </div>

                          

                      </div>
                  </div>
                  <div className="view_ship_ftr text-end">
                    <Link to="/admin/shipments/active" state={{
                        pageDataBack: location.state?.pageData}} className="back_link btn btn_cmn mdl_btn_cancel">Cancel</Link>
                    <button type="submit" className="btn btn_cmn mdl_btn_save">Save Changes</button>
                  </div>
                  </form>
                </div>
              </div>
              <div className="tab-pane fade" id="view_tab2" role="tabpanel" aria-labelledby="view_tab2-tab">
                <div className="view_shipment_body">
                  <div className="view_ship_cont py-0">
                      <div className="row">
                        <div className="col-md-6 py-4">
                          <form className="form_normal" onSubmit={handleSubmit2(onSubmitSendNotification)} noValidate>
                            <div className="mb-3">
                                <label className="form-label">Shipment ID <span className="req_star">*</span></label>
                                <input type="text" readOnly className="form-control" {...register2("shipment")} value="#61234" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Contact Name <span className="req_star">*</span></label>
                                <input type="text" readOnly className="form-control" {...register2("contact_name")} value="Leo Thomas " />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email ID<span className="req_star">*</span></label>
                                <input type="text" className="form-control" {...register2("email")} value="example@xyz.com" />
                            </div>
                            <div className="mb-3">
                                <textarea className={'form-control ' + (errors2.notes ? 'error' : ' ')} {...register2("notes", {required: true})} placeholder="Enter message here..."></textarea>
                                {errors2.notes && errors2.notes.type === "required" && (
                                    <span className='input-error'>Please enter your message.</span>
                                )}
                            </div>
                            <div className="mb-3">
                              <button type="submit" className="btn btn_border_cmn"><img src="/images/btn_message_icon.svg" alt="" /> Send Notification</button>
                            </div>
                          </form>




                        </div>
                        <div className="col-md-6">
                          <div className="noti_history_page">
                            <div className="noti_header">Notification History</div>
                            <div className="noti_list">
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium<strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                              <div className="noti_item d-flex">
                                <div className="noti_pera"><strong>Lorem ipsum</strong> dolor sit amet consectetur. Semper nec velit pretium dolor sit amet consectetur. Semper nec velit pretium <strong>viverra vitae.</strong> </div>
                                <div className="noti_date_main">
                                  <span className="noti_date">23 Nov 23</span>
                                  <span className="noti_hrs">2 hours ago</span>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    
                  </div>
                  <div className="view_ship_ftr text-end">
                    <button type="button" className="btn btn_cmn" onClick={goBack}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
    <Modal className='modal modal_small fade' size="sm" centered show={show} onHide={handleClose}>
        <Modal.Body>
        <div className="send_noti_popup text-center">
              <figure ><img src="/images/noti_success_icon.svg" alt='' /></figure>
              <p className="mb-0">Notification sent successfully!</p>
            </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminViewActiveShipment
