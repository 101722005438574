import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../Components/Header';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../../../Components/Footer';
import Modal from 'react-bootstrap/Modal';
import TableLoader from '../../../Components/TableLoader';
//import CreateDoneePopup from './CreateDoneePopup';
//import ChangePasswordPopup from './ChangePasswordPopup';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Badge, CloseButton } from 'react-bootstrap';

import $ from 'jquery';
import 'datatables.net';
import "datatables.net-dt/css/jquery.dataTables.css";

const AdminMedia = () => {
  const [data, setData] = useState([]);
  const [filterData, updateFilterData] = useState({
    country: [],
    donee: [],
    tag: [],
  });
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [viewDoneeRow, setViewDoneeRow] = useState([]);
  const [show, setShow] = useState(false);
  const [showCreateDonee, setShowCreateDonee] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const tableRef = useRef(null);
  const handleCloseDelete = () => {
    setShowDelete(false);
  }
  const handleShowDelete = () => {
    setShowDelete(true);
  }
  const handleCloseMedia = () => {
    setShowMedia(false);
  }
  const handleShowMedia = (data) => {
    setShowMedia(true);
  }
  const handleDeleteMedia = () => {
    toast.success("Media deleted successfully!");
    setShowDelete(false);
  }
  const handleDownloadMedia = () => {
    toast.info("Media downlaoded successfully!");
  }
  
  useEffect(() => {
    // Initialize DataTable
    const dataTable = $(tableRef.current).DataTable({
      autoWidth: false,
      searching: false,
      "pageLength": 8,
        destroy: true,
        processing: true,
        serverSide: true,
        "filter": true,
        lengthChange: true,
        scrollX: true,
        responsive: true,
        stateSave: true,
        paging: true,
        "lengthMenu": [ 8, 10, 15, 50, 75, 100 ],
        regex: false,
        smart: false,
      ajax: {
        url: "/dummydata/media_data.txt",
        type: "GET",
        data: {
          filterData: filterData
        },
        complete: function() {
            $('.row-delete').on('click', function() {
                //deleters($(this).attr('data-id'), 'blogs', 'delete');
            });
        }
    },
    language: {
      paginate: {
          previous: '<i class="fas fa-chevron-left"></i>',
          next: '<i class="fas fa-chevron-right"></i>'
      }
    },
    "columns": [ {
      data: null,
      sortable: false,
      className: "center",
  }],
  columnDefs: [
    {
      "targets": 0,
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).find('.deleteMedia').on('click', () => {
          handleShowDelete(rowData);
        });
        $(td).find('.media_fig').on('click', () => {
          handleShowMedia(rowData);
        });
        $(td).find('.downloadMedia').on('click', () => {
          handleDownloadMedia(rowData);
        });
      },
      "render": function ( data, type, row ) {
        var cont_str = '';
        if(row.type === 'image'){
          cont_str = `<div class="media_fig"><img src="`+row.url+`" alt="media img" /></div>`;
        }else if(row.type === 'video'){
          cont_str = `<div class="media_fig">
          <img src="`+row.url+`" alt="media img" />
          <div class="play_med" data-src="https://www.youtube.com/embed/9xwazD5SyVg">
              <img src="/images/icon_play.svg" alt="" />
          </div>
       </div>`
        }
        
          return `
          <div class="col-md-12 col-lg-12 col-xl-12">
                         <div class="media_item">
                             `+cont_str+`
                             <div class="media_date_by">
                                 <ul class="date_by_list">
                                     <li>`+row.date+`</li>
                                     <li>By: `+row.donee+`</li>
                                 </ul>
                                 <ul class="media_veiw_del">
                                    <li><a class="downloadMedia" ><img src="/images/icon_view.svg" alt="media img" /></a></li>
                                    <li><a class="deleteMedia"><img src="/images/icon_del.svg" alt="media img" /></a></li>
                                 </ul>
                             </div>
                             <div class="media_title">`+row.description+`</div>
                         </div>
                     </div>`;
      }
    }
  ],
  rowCallback: function(row, data, index) {
    // Render rows as thumbnail grid items
    $(row).addClass('thumbnail-item');
  },
    order: [
      [0, "desc"]
    ]
      // DataTable options go here
    });
    $('.dataTables_length').insertAfter('.dataTables_info');
    $('.dataTables_filter select').addClass('form-select form-select-sm');
    $('.sorting .sort-icon').remove();
    
    $(tableRef.current).on('click', '.deleteMedia', (event) => {
      const rowData = dataTable.row($(event.target).closest('tr').index()).data();
      handleShowDelete();
    });
    // Handle row click event
    // $(tableRef.current).on('click', 'tbody tr', function () {
    //   const rowData = dataTable.row(this).data();
    //   rowData.password = '********';
    //   rowData.country = '+1 USA';
    //   //changePasswordPopupHandler(rowData);
    //   // Perform actions with rowData as needed
    // });

    // Cleanup on component unmount
    return () => {
      $(tableRef.current).off('click', 'tbody tr');
      dataTable.destroy();
    };
  }, [filterData]);
  return (
    <>
    <Header activeMenu="Media" />
    <main className="main-content">
    <section className="content_hdr_sec p-0">
        <div className="container-fluid">
          <div className="row mobile_2row justify-content-between align-items-center">
            <div className="col-md-auto">
            <ul className="nav_tabs_custom">
              <li className='active'><Link to="/admin/media">All Media</Link></li>
            </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="data_table_main">
        <div className="container-fluid">
          <div className="data_table_wrap datatable-custom-design row-hover">
              <table id="myTable1" ref={tableRef} className="table datatable-no-header table-borderless datatable_cmn" >
                <tbody className='thumbnail-grid'>
                 </tbody>
              </table>
          </div>
        </div>
      </section>  
    </main>
    <Modal className='modal fade modal_small' size="sm" centered show={showDelete} onHide={handleCloseDelete}>
        <Modal.Body>
        <div className="send_noti_popup text-center">
          <figure><img src="/images/delete_icon.svg" alt="" /></figure>
            <h4>Delete?</h4>
            <p>Are you sure you want to <br /> delete the file?</p>
            <div className="del_modal_ftrbtn d-flex justify-content-between">
                <button type="button" className="btn btn_cmn mdl_btn_cancel m-2" onClick={()=>handleCloseDelete()}>No</button>
                <button type="button" className="btn btn_cmn mdl_btn_save m-2 alert_togg" onClick={()=>handleDeleteMedia()} data-bs-dismiss="modal">Yes</button>
            </div>
            </div>
        </Modal.Body>
      </Modal>
      <Modal className='modal fade modal_common' size="sm" centered show={showMedia} onHide={handleCloseMedia}>
      <Modal.Header>
       <h5 class="modal-title">View Media</h5>
      </Modal.Header>
        <Modal.Body>
          <div class="row">
                <div class="col-lg-6">
                    <figure class="viewModal_fig mb-0"><img src="/images/media_modal_img.jpg" width="100%" alt="" /></figure>
                </div>
                <div class="col-lg-6">
                    <form class="form_normal">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <label class="form-label">Uploaded On</label>
                                <input type="text" readonly class="form-control" placeholder="27 Oct 2023" />
                            </div>
                            <div class="col-md-12 mb-3">
                                <label class="form-label">Uploaded By</label>
                                <input type="text" readonly class="form-control" placeholder="Donee 1" />
                            </div>
                            <div class="col-md-12 mb-3">
                                <label class="form-label">Description</label>
                                <textarea readonly class="form-control" placeholder="Lorem ipsum dolor sit amet consectetur. Sit a feugiat diam at ut lectus morbi ornare." ></textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" class="btn btn_cmn mdl_btn_save" onClick={()=>handleCloseMedia()}>Close</button>
        </Modal.Footer>
      </Modal>
    <Footer />
    {/*<ChangePasswordPopup handleChangePasswordCancelCallback={handleChangePasswordCancelCallback} handleChangePasswordCallback={handleChangePasswordCallback} doneeInfo={viewDoneeRow} showPopup={show} />
    <CreateDoneePopup handleCreateDoneeCancelCallback={handleCreateDoneeCancelCallback} handleCreateDoneeCallback={handleCreateDoneeCallback} doneeInfo={viewDoneeRow} showPopup={showCreateDonee} />*/}
    </>
  )
}

export default AdminMedia
