import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import useAuth from "../hooks/useAuth";
import axiosInstance from '../api/axiosInstance';
import { useContext } from "react";
import {AuthContext} from "../Context/ProvideAuth";

const Logout = () => {
  const navigate = useNavigate();
  const {auth, setAuth} = useContext(AuthContext);
  const onLogout = async () => {
        try {
          const res = await axiosInstance.post('/logout', {})
          .then(response => {
            setAuth({});
            navigate('/login');
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
        catch(err){
            if(!err?.response){
              //toast.error('no server response');
            }
            else {
                //toast.error('Login failed');
            }
        }
  }
useEffect(()=>{
  let ignore = false;
  onLogout();
  return () => {
      ignore = true;
    }
},[]);
  return (                                       
    <main className="main-content main_vert_center">
      
    </main>
  )
}

export default Logout
