import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
const Footer = () => {
  return (
    <>
      <footer className="footer_sec">
      <section className="ftr_copyright text-center">
        <div className="container-fluid">
          <p className="mb-0">2024 © All rights reserved.</p>
        </div>
      </section>
    </footer>
    <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnFocusLoss={false}
        pauseOnHover
        theme="colored"
        />
    </>
  )
}

export default Footer
