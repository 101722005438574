import { useNavigate, useLocation, useParams } from "react-router";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form"
import axiosInstance from '../api/axiosInstance';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params= useParams()
  const from = location.state?.from?.pathname || "/linkpage"
  const [error, setError] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password'); 
  const [cPasswordFieldType, setCPasswordFieldType] = useState('password'); 
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      cpassword: ""
    },
  })
  const watchUsername = watch()
  //const watchUsername = watch("username")
  const API = axios.create({
    baseURL: 'http://localhost:5000',
    withCredentials: true,
  })

const onSubmit  = async (data) => {
      try {
        var form = new FormData();
        form.append("password", data.password);
        form.append("reset_token", data.reset_token);
        const res = await axiosInstance.post('/reset-password', form)
        .then(res=> {
          var result_data = res.data;
          if(result_data?.status === 'Ok'){
            toast.success(result_data.message);
            setTimeout(function(){
              navigate('/login');
            },3000);
          }
          else{
            toast.error(result_data.message);
          }
        })
      }
      catch(err){
          if(!err?.response){
              setError('no server response');
          }
          else {
              setError('registeration failed')
          }
      }
}
const changePasswordField = () => {
  if(passwordFieldType === 'password'){
    setPasswordFieldType('text');
  }else{
    setPasswordFieldType('password');
  }
}
const changeCPasswordField = () => {
  if(cPasswordFieldType === 'password'){
    setCPasswordFieldType('text');
  }else{
    setCPasswordFieldType('password');
  }
}
useEffect(() => {
  setValue('reset_token',params.key);
  $(document).ready(function() {

    // form label move on focus start
    $(".form_group .form-control").focus(function(){
      $(this).parent().addClass('focus_label');
     })
     .blur(function(){
      var tmpval = $(this).val();
      if(tmpval === '') {
          $(this).parent().removeClass('focus_label');
      }
    })
    // form label move on focus end
  });  //document.ready end
 },[])

  return (                                       
    <main className="main-content main_vert_center">
      <div className="login_flow_wrap">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <div className="loginflow_right">
              <div className="loginflow_logo">
                <img src="/images/logo.svg" alt="logo" />
              </div>
              <h1>Reset Password</h1>
              <form className="form_common" onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="form_group">
                  <label  className="form-label">Enter New Password <span className="req_star">*</span></label>
                  <input type={passwordFieldType} className={'form-control ' + (errors.password ? 'error' : ' ')} autoComplete='off' 
                  {...register("password", { 
                    required: "Email is required",
                    pattern: {
                      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                      message: "Invalid password"
                    }
                   })} />
                  <i className={'pass_vis_icon ' + (passwordFieldType === 'password' ? '' : 'on')} id="verifyPassword" onClick={changePasswordField}> 
                    <img className="iconeye" src="/images/icon-eye-slash.svg" alt="" /> 
                    <img className="iconeyeslash" src="/images/icon-eye.svg" alt="" />
                  </i>
                  {errors.password && errors.password.type === 'required' && <span className='input-error'>Please enter new password.</span>}
                  {errors.password && errors.password.type === 'pattern' && <span className='input-error'>Password must be 8 characters long with a mix of alphanumeric characters with one uppercase letter.</span>}
                </div>

                <div className="form_group">
                  <label  className="form-label">Confirm New Password <span className="req_star">*</span></label>
                  <input type={cPasswordFieldType} className={'form-control ' + (errors.cpassword ? 'error' : ' ')} autoComplete='off' {...register("cpassword", 
                  {
                    required: true,
                     message: "Test field required",
                    validate: (val) => {
                      if (watch('cpassword') === '') {
                        return "Enter confirm password";
                      }else if (watch('password') !== val) {
                        return "Confirm password must be same as new password.";
                      }
                    },
                  })} />
                  <i className={'pass_vis_icon ' + (cPasswordFieldType === 'password' ? '' : 'on')} onClick={changeCPasswordField}> 
                    <img className="iconeye" src="/images/icon-eye-slash.svg" alt="" /> 
                    <img className="iconeyeslash" src="/images/icon-eye.svg" alt="" />
                  </i>
                  {errors.cpassword?.message && <span className='input-error'>{errors.cpassword?.message}</span>}
                  {errors.cpassword && errors.cpassword.type === 'required' && <span className='input-error'>Please enter confirm password.</span>}
                </div>

             
                <div className="frm_btn_full">
                  <button type="submit" className='btn btn_cmn '>Change Password</button>
                </div>
                <div className="frm_btm_link text-center">
                  <Link to="/login"><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</Link>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="loginflow_left">
              <figure><img src="/images/login_flow_left_img.jpg" alt="Login Image" /></figure>
              <div className="loginflow_left_cap">
                <h4>Lorem ipsum dolor sit amet consectetur. Nulla elementum </h4>
                <p className="mb-0">Lorem ipsum dolor sit amet consectetur. Pellentesque id vel nisl volutpat maecenas. Vitae nulla viverra dolor eget interdum massa ac sagittis.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnFocusLoss={false}
        pauseOnHover
        theme="colored"
        />
    </main>
  )
}

export default ResetPassword