import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import {AuthContext} from "../Context/ProvideAuth";

import React from 'react'

const Auth = ({ allowedRoles }) => {
    const {auth} = useContext(AuthContext);
    const location = useLocation();
  return (
    allowedRoles.find(role => auth?.role?.includes(role))
     // auth.role.find(role => allowedRoles?.includes(role))
      ? <Outlet/>
      : auth?.token
        ? <Navigate to="/unauthorized" state={{ from: location}} replace/>
        : <Navigate to="/login" state={{from: location}} replace/>
  )
}

export default Auth;
