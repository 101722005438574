import axios from 'axios';
import { useContext } from 'react';
import {getSession} from "../Context/ProvideAuth";


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL+'/api', // Replace with your API base URL
  headers: {
  }
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    config => {
        var auth_data = getSession();
      const token = auth_data?.token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

export default axiosInstance;
