import axiosInstance from '../api/axiosInstance';
const doneeService = {
  getAll: async () => {
    try {
      const response = await axiosInstance.get(`/items`);
      return response.data;
    } catch (error) {
      console.error('Error fetching items:', error);
      throw error;
    }
  },
  getById: async (itemId) => {
    try {
      const response = await axiosInstance.get(`/items/${itemId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching item with ID ${itemId}:`, error);
      throw error;
    }
  },
  create: async (newItemData) => {
    try {
      const response = await axiosInstance.post(`/create-donee`, newItemData);
      return response.data;
    } catch (error) {
      console.error('Error creating item:', error);
      throw error;
    }
  },
  update: async (updatedItemData) => {
    try {
      const response = await axiosInstance.post(`/update-donee`, updatedItemData);
      return response.data;
    } catch (error) {
      console.error(`Error updating item with ID:`, error);
      throw error;
    }
  },
  delete: async (itemId) => {
    try {
      const response = await axiosInstance.delete(`/items/${itemId}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting item with ID ${itemId}:`, error);
      throw error;
    }
  }
  
};

export default doneeService;