import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import { toast } from 'react-toastify';
import { countryArr } from '../../../Constants/GlobalDataArr';
import { defaultTheme } from 'react-select';
import Button from 'react-bootstrap/Button';

import {
	Menu,
	Blanket,
	Dropdown,
	Svg,
	DropdownIndicator
  } from '../../../Components/SelectDropdownCustom';
  const selectStyles = {
    control: provided => ({ ...provided, minWidth: 240, margin: 8}),
    menu: () => ({ maxHeight: 200, overflowY: 'auto' }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: 6,
      opacity: '0.8',
      left: 6,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: '30px'
    }),
  };
const AssignContactPopup = (props) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [doneeList, setDoneeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [selectedEmailOptions, setSelectedEmailOptions] = useState();
  const [isOpen, setIsOpen] =useState(false);
  const toggleOpen = () => {
    if(isOpen === true){
      setIsOpen(false);
    }else{
      setIsOpen(true);
    }
    
  };
  const handleClose = () => {
    props.handleAssignContactCancelCallback('');
    setShow(false);
  }
  const handleShow = () => {
    setValue('assignment_status', false);
    setShow(true);
  }
  const handleEditContact = () => setEditMode(true);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
        id: "",
        name: "",
        email: "",
        country: "",
        assignment_status: false
    },
  })
  const watchEmail = watch('email');
  const watchStatus = watch('assignment_status');
  const emailOptionList = [
    { value: 1, label: "welcome@xyz.com" },
    { value: 2, label: "welcome1@xyz.com" },
    { value: 3, label: "welcome2@xyz.com" },
    { value: 4, label: "welcome3@xyz.com" },
    { value: 5, label: "welcome4@xyz.com" }
  ];
  const onSubmit = (data) => {
    props.handleAssignContactCallback(data);
    try {
        /*const res = await API.post('/reset-password', {
          password: data.password, cpassword: data.cpassword, key: data.password
        })
        .then(res=> {
          if(res?.data.name){
            const role=res?.data.role;
            console.log({"role":`${role}`, "name":`${name}`})
            setAuth({"role":`${role}`, "name":`${name}`});
          setName('');
          setPassword('');
         navigate(from, {replace : true});
          }
          else{
            console.log('incorrect submission');
            setError(res.message);
          }
        })*/
        //navigate(from, {replace : true});
        if(data.assignment_status === 'completed'){
          toast.success("Shipment Completed. View in completed shipment");
        }else{
          toast.success("Shipment Assigned. View in active shipment");
        }
        setShow(false);
      }
      catch(err){
          if(!err?.response){
              setError('no server response');
          }
          else {
              setError('update failed')
          }
      }
    
  }
  const handleSelect = (data) =>{
    setSelectedEmailOptions(data);
    setValue('email', data);
  }
  useEffect(()=>{
    if(props.showPopup === true){
      handleShow();
    } else {
      handleClose();
    }
    setData(props.contactInfo);
    setEditMode(false);
    var doneeArr = [
        {id: 1, donee_name: "Donee 1"},
        {id: 2, donee_name: "Donee 2"},
        {id: 3, donee_name: "Donee 3"},
        {id: 4, donee_name: "Donee 4"},
        {id: 5, donee_name: "Donee 5"},
        {id: 6, donee_name: "Donee 6"},
        {id: 7, donee_name: "Donee 7"},
    ]
    setDoneeList(doneeArr);
    setCountryList(countryArr);
    setValue('email', '');
    setValue('donee', '1');
    setValue('country', '3');
    setSelectedEmailOptions(null);
  },[props])
   return (
    <>
    <Modal className='modal modal_medium fade modal_common' size="sm" show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
          <Modal.Title>Assign Contact</Modal.Title>
        </Modal.Header>
        <form className="form_normal" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
            <div className="pop_cont_form">
                <div className="row">
                     <div className="col-md-12 mb-3">
                        <label className="form-label">Donee <span className="req_star">*</span></label>
                        <select className="form-select" {...register("donee", {required: true})}>
                        {doneeList.map(function(option) {
                            return (
                                <option value={option.id} key={`donee-key-`+option.id}>{option.donee_name}</option>
                                )
                            })}
                        </select>
                        {errors.donee && errors.donee.type === "required" && (
                            <span className='input-error'>This field is required.</span>
                        )}
                    </div>
                    <div className="col-md-12 mb-3">
                        <label className="form-label">Country <span className="req_star">*</span></label>
                        <select className="form-select" {...register("country", {required: true})}>
                        {countryList.map(function(countryOption) {
                            return (
                                <option value={countryOption.value} key={`country-key-`+countryOption.value}>{countryOption.label}</option>
                                )
                            })}
                        </select>
                        {errors.country && errors.country.type === "required" && (
                            <span className='input-error'>This field is required.</span>
                        )}
                    </div>
                    <div className="col-md-12 mb-3">
                        <label className="form-label">Email ID <span className="req_star">*</span></label>
                        <Dropdown
                          isOpen={isOpen}
                          onClose={toggleOpen}
                          target={
                            <Button
                            className={'form-select basic-single single-custom-dropdown'+ ((errors.email && watchEmail === '') ? ' error': '')}
                              onClick={toggleOpen}
                              active={isOpen}
                            >
                              {selectedEmailOptions ? `${selectedEmailOptions.label}` : 'Select Email ID'} 
                                </Button>
                          }
                        >
                          <Select
                          className={'basic-single react-select-container react-select-container-absolute'+ ((errors.email && watchEmail === '') ? ' error': '')}
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            menuIsOpen
                            {...register("email", {required: watchStatus !== 'completed'})}
                            onChange={handleSelect}
                            options={emailOptionList}
                            styles={selectStyles}
                            placeholder="Select Email ID"
                            tabSelectsValue={false}
                            value={selectedEmailOptions}
                          />
                        </Dropdown>
                        {/*<Select className={'basic-single'+ ((errors.email && watchEmail === '') ? ' error': '')}
                            classNamePrefix="select"
                            
                            isClearable={false}
                            //defaultValue={emailOptionList[1]}
                            onChange={handleSelect}
                            menuPlacement="bottom"
                            value={selectedEmailOptions}
                            name="email"
                            isDisabled={watchStatus === 'completed'}
                            isSearchable={true}
                            placeholder="Select Email ID"
                        options={emailOptionList} />*/}
                            {errors.email && watchEmail === '' && errors.email.type === "required" && (
                            <span className='input-error'>Select email ID to assign.</span>
                        )}
                    </div>
                    <div className="col-md-12 mb-2">
                      <div className='form-check'>
                        <input type="radio" id="confirm_assignment" value='assigned' className="form-check-input" {...register("assignment_status", {required: true})} />
                        <label htmlFor="confirm_assignment" className="form-radio-label"> Confirm Assignment</label>
                        {errors.assignment_status && errors.assignment_status.type === "required" && (
                            <span className='input-error input-error-left'>Select assignment status.</span>
                        )}
                      </div>
                    </div>
                      <div className="col-md-6 mb-2">
                        <div className="row vertical-center-custom">
                          <div className="col-5"><hr /></div>
                          <div className="col-2 between-text"> Or</div>
                          <div className="col-5"><hr /></div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className='form-check'>
                          <input type="radio" id="mark_complete"  value='completed' className="form-check-input" {...register("assignment_status", {required: true})} />
                          <label htmlFor="mark_complete" className="form-radio-label">Mark Assignment as Completed</label>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn_cmn mdl_btn_cancel" onClick={handleClose}>Cancel</button>
            <button type="submit" className="btn btn_cmn mdl_btn_save">Save Changes</button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default AssignContactPopup
