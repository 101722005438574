import axiosInstance from '../api/axiosInstance';
const contactService = {
  getById: async (itemId) => {
    try {
      const response = await axiosInstance.get(`/get-user-detail?id=${itemId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching item with ID ${itemId}:`, error);
      throw error;
    }
  },
  update: async (updatedItemData) => {
    try {
      const response = await axiosInstance.post(`/update-contact`, updatedItemData);
      return response.data;
    } catch (error) {
      console.error(`Error updating item with ID:`, error);
      throw error;
    }
  },
  delete: async (itemId) => {
    try {
      const response = await axiosInstance.delete(`/items/${itemId}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting item with ID ${itemId}:`, error);
      throw error;
    }
  }
  
};

export default contactService;