import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import doneeService from '../../../services/doneeService';
import $ from 'jquery';
const ContactViewPopup = (props) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('text'); 
  const [cPasswordFieldType, setCPasswordFieldType] = useState('text'); 
  const [crPasswordFieldType, setCrPasswordFieldType] = useState('text'); 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const { 
    register: register2,
    watch: watch2,
    setValue: setValue2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    } = useForm(
    {
        defaultValues: {
          password: "",
          cpassword: ""
        },
    }
  );
  const watchFormData = watch2();
  const onSubmitChangePassword = (data) => {
    try {
      var formData = new FormData();
      formData.append("donee_id", data.id);
      formData.append("type", 'password');
      formData.append("password", data.password);
      doneeService.update(formData)
        .then(data => {
          var result_data = data;
          if(data.status === 'Ok'){
            handleClose();
            toast.success(result_data.message);
          } else{
            toast.error(result_data.message);
          }
        })
        .catch(error => console.error('Error fetching users:', error));
        
      }
      catch(err){
          if(!err?.response){
              setError('no server response');
          }
          else {
              setError('update failed')
          }
      }
    
  }
  useEffect(()=>{
    if(props.showPopup === true){
      handleShow();
    } else {
      handleClose();
    }
    setValue2('id',props.doneeInfo.id);
    setValue2('password','');
    setValue2('cpassword','');
    setData(props.doneeInfo);
  },[props])
  useEffect(()=>{
    $(".form_group .form-control").focus(function(){
      console.log('focus');
      $(this).parent().addClass('focus_label');
     })
     .blur(function(){
      var tmpval = $(this).val();
      if(tmpval === '') {
          $(this).parent().removeClass('focus_label');
      }
    })
  },[watchFormData]);
  return (
    <>
      <Modal className='modal fade modal-change-password' backdropClassName="modal-change-password-backdrop1" centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <form className="form_common" onSubmit={handleSubmit2(onSubmitChangePassword)} noValidate>
        <Modal.Body>
        <div className="pop_cont_form">
            <div className="row">
            <div className="col-md-12 mt-4 mb-1">
                <div className="form_group">
                    <label  className="form-label">New Password <span className="req_star">*</span></label>
                    <input type={passwordFieldType} className={'form-control ' + (errors2.password ? 'error' : ' ')} autoComplete='off' 
                    {...register2("password", { 
                      required: true,
                      pattern: {
                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message: "Invalid password"
                      }
                    })} />
                    {errors2.password && errors2.password.type === 'required' && <span className='input-error'>Please enter new password.</span>}
                    {errors2.password && errors2.password.type === 'pattern' && <span className='input-error'>Password must be 8 characters long with a mix of alphanumeric characters with one uppercase letter.</span>}
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className="form_group">
                    <label  className="form-label">Confirm Password <span className="req_star">*</span></label>
                    <input type={cPasswordFieldType} className={'form-control ' + (errors2.cpassword ? 'error' : ' ')} autoComplete='off' {...register2("cpassword", 
                    {
                      required: true,
                      message: "Test field required",
                      validate: (val) => {
                        if (watch2('cpassword') === '') {
                          return "Please enter confirm password.";
                        }else if (watch2('password') !== val) {
                          return "Confirm password must be same as new password.";
                        }
                      },
                    })} />
                    {errors2.cpassword?.message && <span className='input-error'>{errors2.cpassword?.message}</span>}
                    {errors2.cpassword && errors2.cpassword.type === 'required' && <span className='input-error'>Please enter confirm password.</span>}
                  </div>
                </div>
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn_cmn mdl_btn_cancel custom-btn-padding" onClick={handleClose}>Cancel</button>
            <button type="submit" className="btn btn_cmn mdl_btn_save custom-btn-padding">Save Changes</button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ContactViewPopup
