import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../Components/Header';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../../../Components/Footer';
import TableLoader from '../../../Components/TableLoader';
import ContactViewPopup from './ContactViewPopup';
import axiosInstance from '../../../api/axiosInstance';
import { useContext } from "react";
import {AuthContext} from "../../../Context/ProvideAuth";
import $ from 'jquery';
import 'datatables.net';
import "datatables.net-dt/css/jquery.dataTables.css";

const AdminContacts = () => {
  const {auth, setAuth} = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [viewContactRow, setViewContactRow] = useState([]);
  const [show, setShow] = useState(false);
  const tableRef = useRef(null);
  const myNewTheme= {
    rows: {
      fontSize: '16px'
    }
  }
  const handleUpdateContactCallback = (value) => {
    setShow(false);
    $("#myTable1").DataTable().ajax.reload();
  };
  const handleSearch = (e) => {
    setShow(false);
    var searchText = e.target.value;
    setSearchString(searchText);
  }
  const viewContactPopupHandler = row => {
    if(row?.id){
      setViewContactRow(row);
      setShow(true);
    }else{
      setShow(false);
    }
  }
  function PhoneNumberDisplay(phoneNumber) {
    //const phoneNumber = "(805) 123-7568";
    // Remove non-numeric characters
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Format the phone number as (xxx) xxx-xxxx
  const formattedPhoneNumber = `(${numericPhoneNumber.substring(0, 3)}) ${numericPhoneNumber.substring(3, 6)}-${numericPhoneNumber.substring(6)}`;

    return formattedPhoneNumber;
  }
  useEffect(() => {
    // Initialize DataTable
    const dataTable = $(tableRef.current).DataTable({
      autoWidth: false,
        destroy: true,
        processing: true,
        serverSide: true,
        "filter": true,
        lengthChange: true,
        scrollX: true,
        responsive: true,
        stateSave: true,
        paging: true,
        "lengthMenu": [ 10, 15, 50, 75, 100 ],
        regex: false,
        smart: false,
      ajax: {
        url: process.env.REACT_APP_API_URL+"/api/get-admin-users?user_type=contact",
        type: "GET",
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer '+auth.token);
        },
        data: function (data) {
          data['order'].forEach(function(items, index) {
            data['order'][index]['column'] = data['columns'][items.column]['data'];
          });
          data.sort_column = data.order[0].column;
          data.sort_order = data.order[0].dir;
          data.search_key = data.search.value;
          return data;
        },
        complete: function() {
            $('.row-delete').on('click', function() {
                //deleters($(this).attr('data-id'), 'blogs', 'delete');
            });
        }
    },
    language: {
      paginate: {
          previous: '<i class="fas fa-chevron-left"></i>',
          next: '<i class="fas fa-chevron-right"></i>'
      }
    },
    "columns": [ {
        "data": "first_name"
    },{
      "data": "last_name"
    },{
      "data": "phone_number",
      sorting: false,
      "render": function ( data, type, row ) {
        var cont_str = PhoneNumberDisplay(row.phone_number);
        return '<div class="font_small">'+cont_str+'</div>';
      }
    },{
        "data": "email"
    },{
      "data": "donee_name"
  }],
    order: [
      [0, "desc"]
    ]
      // DataTable options go here
    });
    $('.table_search .dataTables_filter').remove();
    $('.dataTables_filter').insertAfter('.table_search > img');
    $('.dataTables_filter input').addClass('form-control form-control-sm');
    $('.dataTables_filter .form-control').attr('placeholder', 'Search');
    $('.dataTables_length').insertAfter('.dataTables_info');
    $('.dataTables_filter select').addClass('form-select form-select-sm');
    $('.sorting .sort-icon').remove();
    $('.sorting_disabled .sort-icon').remove();
    dataTable.columns().iterator( 'column', function (ctx, idx) {
      $( dataTable.column(idx).header() ).append('<span class="sort-icon"/>');
    } );
    // Handle row click event
    $(tableRef.current).on('click', 'tbody tr', function () {
      const rowData = dataTable.row(this).data();
      rowData.password = '********';
      viewContactPopupHandler(rowData);
      // Perform actions with rowData as needed
    });

    // Cleanup on component unmount
    return () => {
      $(tableRef.current).off('click', 'tbody tr');
      dataTable.destroy();
    };
  }, []);
  
  return (
    <>
    <Header activeMenu="Contacts" />
    <main className="main-content">
    <section className="content_hdr_sec">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
              <h1 className="page_head">All Contacts</h1>
            </div>
            <div className="col-auto">
              <div className="cont_hdr_right">
                {/*<div className="cont_hdr_item"><a href="#"><img src="/images/export_icon.svg" alt='' /></a></div>*/}
                <div className="cont_hdr_item table_search">
                  <img className="" src="/images/search_icon.svg" alt="" />
                  <div id="DataTables_Table_0_filter" className="dataTables_filter"><label>Search:<input type="search" name="search" value={searchString} onChange={handleSearch} className="form-control form-control-sm" placeholder="Search" /></label></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="data_table_main">
        <div className="container-fluid">
          <div className="data_table_wrap datatable-custom-design row-hover">
              <table id="myTable1" ref={tableRef} className="table table-borderless datatable_cmn" >
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone Number</th>
                        <th>Email ID</th>
                        <th>Donee</th>
                    </tr>
                </thead>
                <tbody>
                 </tbody>
              </table>
          </div>
        </div>
      </section>
    </main>
    <Footer />
    <ContactViewPopup handleUpdateContactCallback={handleUpdateContactCallback} contactInfo={viewContactRow} showPopup={show} />
    </>
  )
}

export default AdminContacts
