import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import contactService from '../../../services/contactService';
import commonService from '../../../services/commonService';
import { countryArr } from '../../../Constants/GlobalDataArr';
import { Link } from 'react-router-dom';
const ContactViewPopup = (props) => {
  const [data, setData] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [doneeList, setDoneeList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('text'); 
  const [cPasswordFieldType, setCPasswordFieldType] = useState('text'); 
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChangePasswordClose = () => setShowChangePassword(false);
  const handleChangePasswordShow = () => {
    setValue2('password', '');
    setValue2('cpassword', '');
    setShowChangePassword(true);
  }
  const handleEditContact = () => setEditMode(true);
  const disableEditMode = () => setEditMode(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
        id: "",
        name: "",
        email: "",
        country: "",
    },
  })
  const watchCountry = watch('country');
  const { 
    register: register2,
    watch: watch2,
    setValue: setValue2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    } = useForm(
    {
        defaultValues: {
            id: "",
            password: "",
            cpassword: ""
        },
    }
  );
  const onSubmit = (data) => {
    console.log(data);
    try {
      var formData = new FormData();
      formData.append("email", data.email);
      formData.append("country_id", data.country);
      formData.append("donee_id", data.donee);
      formData.append("user_id", data.id);
      contactService.update(formData)
      .then(data => {
        console.log(data);
        var result_data = data;
        if(data.status === 'Ok'){
          props.handleUpdateContactCallback(data);
          toast.success(result_data.message);
          setShow(false);
        } else{
          toast.error(result_data.message);
        }
      })
      .catch(error => console.error('Error fetching users:', error));
      //  toast.success("User details edited successfully");
        
      }
      catch(err){
          if(!err?.response){
              setError('no server response');
          }
          else {
              setError('update failed')
          }
      }
    
  }
  const onSubmitChangePassword = (data) => {
    var formData = new FormData();
      formData.append("user_id", data.id);
      formData.append("type", 'password');
      formData.append("password", data.password);
      contactService.update(formData)
        .then(data => {
          var result_data = data;
          if(data.status === 'Ok'){
            handleClose();
            toast.success(result_data.message);
            setShowChangePassword(false);
          } else{
            toast.error(result_data.message);
          }
        })
        .catch(error => console.error('Error fetching users:', error));
        
      }
    
  useEffect(()=>{
    if(props.showPopup === true){
      handleShow();
    } else {
      handleClose();
    }
    setEditMode(false);
    console.log(props.contactInfo);
    if(props.contactInfo && props.contactInfo.id !== undefined){
      contactService.getById(props.contactInfo.id)
      .then(data => {
        setContactData(data.data);
      })
      .catch(error => console.error('Error fetching users:', error));
    
    
      setData(props.contactInfo);
      var contArr = [];
      commonService.getCountryList()
      .then(data => {
        data.data.forEach(row => {
          if(row.id == props.contactInfo.country){
            setValue('country_name', '+'+row.code+' '+row.name);
          }
          
          contArr.push({
            label: '+'+row.code+' '+row.name,
            value: row.id
          })
        })
        setCountryList(contArr);
        setTimeout(function(){
        setValue('country', props.contactInfo.country);
        }, 1000);
      })
      .catch(error => console.error('Error fetching users:', error));
      
      //setCountryList(countryArr);
      setValue('email', props.contactInfo.email);
      
      setValue('id', props.contactInfo.id);
      setValue('donee_name', props.contactInfo.donee_name);
      setValue('country_name', props.contactInfo.country);
      setValue2('id', props.contactInfo.id);
      setValue('name', props.contactInfo.first_name+' '+props.contactInfo.last_name);
    }
  },[props])
  useEffect(()=>{
    if(props.contactInfo && props.contactInfo.country !== undefined){
    //get donee list by country
    var doneeArr = [];
    console.log(watchCountry);
    commonService.getDoneeListByCountry(watchCountry)
    .then(data => {
      data.data.forEach(row => {
        doneeArr.push({
          label: row.first_name+' '+row.last_name,
          value: row.id
        })
      })
      setDoneeList(doneeArr);
      setTimeout(function(){
        setValue('donee', props.contactInfo.donee_id);
      },1000);
    })
    .catch(error => console.error('Error fetching users:', error));
    }
  },[watchCountry])
  return (
    <>
    <Modal className='modal fade modal_common' centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
          <Modal.Title>View Contact</Modal.Title>
          {!editMode && <button type="button" onClick={handleEditContact} className="btn btn_cont_edit"><img src="/images/edit_icon.svg" alt='' /> Edit</button>}
        </Modal.Header>
        <form className="form_normal" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
        <div className="pop_cont_form">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Name <span className="req_star">*</span></label>
                            <input type="text" readOnly className="form-control" {...register("name", {required: false})} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Email ID <span className="req_star">*</span></label>
                            <input type="email" readOnly={editMode === false} {...register("email", {required: true, 
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: "Please enter a valid email.",
                            }})} className={'form-control ' + (errors.email ? 'error' : ' ')} />
                            {errors.email && errors.email.type === "required" && (
                                <span className='input-error'>This field is required.</span>
                            )}
                            {errors.email && errors.email.type === "pattern" && (
                                <span className='input-error'>{errors.email.message}</span>
                            )}
                        </div>
                        {!editMode && <div className="col-md-6 mb-3">
                            <label className="form-label">Donee <span className="req_star">*</span></label>
                            <input type="text" readOnly className="form-control" {...register("donee_name", {required: false})} />
                        </div>}
                        {editMode && <div className="col-md-6 mb-3">
                            <label className="form-label">Donee <span className="req_star">*</span></label>
                            <select className="form-select" {...register("donee", {required: true})}>
                              <option value="">Select</option>
                            {doneeList.map(function(option) {
                                return (
                                    <option value={option.value} key={`donee-key-`+option.value}>{option.label}</option>
                                    )
                                })}
                            </select>
                            {errors.donee && errors.donee.type === "required" && (
                                <span className='input-error'>This field is required.</span>
                            )}
                        </div>}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Phone Number <span className="req_star">*</span></label>
                            <input type="text" readOnly className="form-control" value={data.phone_number} />
                        </div>
                        {!editMode && <div className="col-md-6 mb-3">
                            <label className="form-label">Country <span className="req_star">*</span></label>
                            <input type="text" readOnly className="form-control" {...register("country_name", {required: false})} />
                        </div>}
                        {editMode && <div className="col-md-6 mb-3">
                            <label className="form-label">Country <span className="req_star">*</span></label>
                            <select className="form-select" {...register("country", {required: true})}>
                            {countryList.map(function(countryOption) {
                                return (
                                    <option value={countryOption.value} key={`country-key-`+countryOption.value}>{countryOption.label}</option>
                                    )
                                })}
                            </select>
                            {errors.country && errors.country.type === "required" && (
                                <span className='input-error'>This field is required.</span>
                            )}
                        </div>}
                        <div className="col-md-6 mb-3">
                            <label className="form-label">Password <span className="req_star">*</span></label>
                            <input type="text" readOnly className="form-control" value={data.password} />
                            {!editMode && <div className='float-end'><Link onClick={handleChangePasswordShow}>Change Password?</Link></div>}
                        </div>
                    </div>
                <div className="table_v_scroll">
                    <div className="table-responsive">
                        <table className="table_normal_cmn table table-borderless">
                            <thead className="table-light">
                                <tr>
                                    <th>Shipment ID</th>
                                    <th>Ship Date</th>
                                    <th>Donee</th>
                                    <th>Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>#61234</td>
                                    <td>12 Oct 2023</td>
                                    <td>Lorem Ipsum</td>
                                    <td>United States</td>
                                </tr>
                                <tr>
                                    <td>#61234</td>
                                    <td>12 Oct 2023</td>
                                    <td>Lorem Ipsum</td>
                                    <td>United States</td>
                                </tr>
                                <tr>
                                    <td>#61234</td>
                                    <td>12 Oct 2023</td>
                                    <td>Lorem Ipsum</td>
                                    <td>United States</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            {!editMode && <button type="button" className="btn btn_cmn mdl_btn_close" onClick={handleClose}>Close</button>}
            {editMode && <button type="button" className="btn btn_cmn mdl_btn_cancel" onClick={disableEditMode}>Cancel</button>}
            {editMode && <button type="submit" className="btn btn_cmn mdl_btn_save">Save Changes</button>}
        </Modal.Footer>
        </form>
      </Modal>
      <Modal className='modal fade modal-change-password' backdropClassName="modal-change-password-backdrop" centered show={showChangePassword} onHide={handleChangePasswordClose}>
        <Modal.Header className="modal-header">
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <form className="form_normal" onSubmit={handleSubmit2(onSubmitChangePassword)} noValidate>
        <Modal.Body>
        <div className="pop_cont_form">
            <div className="row">
                <div className="form_group mb-3">
                  <label  className="form-label">New Password <span className="req_star">*</span></label>
                  <input type={passwordFieldType} className={'form-control ' + (errors2.password ? 'error' : ' ')} autoComplete='off' 
                  {...register2("password", { 
                  required: true,
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message: "Invalid password"
                  }
                 })} />
                  {errors2.password && errors2.password.type === 'required' && <span className='input-error'>Please enter new password.</span>}
                  {errors2.password && errors2.password.type === 'pattern' && <span className='input-error'>Password must be 8 characters long with a mix of alphanumeric characters with one uppercase letter.</span>}
                </div>

                <div className="form_group mb-3">
                  <label  className="form-label">Confirm Password <span className="req_star">*</span></label>
                  <input type={cPasswordFieldType} className={'form-control ' + (errors2.cpassword ? 'error' : ' ')} autoComplete='off' {...register2("cpassword", 
                  {
                    required: true,
                     message: "Test field required",
                    validate: (val) => {
                      if (watch2('cpassword') === '') {
                        return "Please enter confirm password.";
                      }else if (watch2('password') !== val) {
                        return "Confirm password must be same as new password.";
                      }
                    },
                  })} />
                  {errors2.cpassword?.message && <span className='input-error'>{errors2.cpassword?.message}</span>}
                  {errors2.cpassword && errors2.cpassword.type === 'required' && <span className='input-error'>Please enter confirm password.</span>}
                </div>
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn_cmn mdl_btn_cancel" onClick={handleChangePasswordClose}>Cancel</button>
            <button type="submit" className="btn btn_cmn mdl_btn_save">Save Changes</button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ContactViewPopup
