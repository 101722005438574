import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const StatusMenu = (props) => {
  const [activeMenu, setActiveMenu] = useState('');
  const [pendingCount, setPendingCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  useEffect(()=>{
    if(props.activeMenu !== ''){
      setActiveMenu(props.activeMenu);
      setPendingCount(props.pendingCount);
      setActiveCount(props.activeCount);
    }
    
  },[props])
  return (
    <>
      <ul className="nav_tabs_custom">
        <li className={(activeMenu === 'pending' ? 'active': '')}><Link to="/admin/shipments">Pending <span className="tab_badge">{pendingCount}</span></Link></li>
        <li className={(activeMenu === 'active' ? 'active': '')}><Link to="/admin/shipments/active">Active <span className="tab_badge">{activeCount}</span></Link></li>
        <li className={(activeMenu === 'completed' ? 'active': '')}><Link to="/admin/shipments/completed">Completed</Link></li>
      </ul>
    </>
  )
}

export default StatusMenu
