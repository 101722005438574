import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useContext } from "react";
import {AuthContext} from "../Context/ProvideAuth";
import { Link, useNavigate } from 'react-router-dom';
//import Button from 'react-bootstrap/Button';
//import Container from 'react-bootstrap/Container';
//import Form from 'react-bootstrap/Form';
//import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
//import Offcanvas from 'react-bootstrap/Offcanvas';
import $ from 'jquery';

const Header = (props) => {
  const navigate = useNavigate();
  const {auth, setAuth} = useContext(AuthContext);
  const [activeMenu, setActiveMenu] = useState('');
  const logOutUser = () => {
    setAuth({});
    navigate('/login');
  }
  const handleRightDropdown = () => {
    if($(window).width() < 992){
      $("#right_dropdown").slideToggle();
    }
  }
  useEffect(()=>{
    if(props.activeMenu){
      setActiveMenu(props.activeMenu);
    }
  },[props])
  useEffect(()=>{
    document.body.classList.add('bg_light');
    document.body.classList.add('body_admin');
  },[])
  return (
    <>
      <header className="header_sec">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/"><img src="/images/logo_white.svg" alt="logo" width="41" height="42" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {auth.role === 'admin' && 
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className={'nav-link '+ (activeMenu === 'Shipments' ? 'active': '')} to="/admin/shipments">Shipments</Link>
                </li>
                <li className="nav-item">
                  <Link className={'nav-link '+ (activeMenu === 'Contacts' ? 'active': '')} to="/admin/contacts">Contacts</Link>
                </li>
                <li className="nav-item">
                  <Link className={'nav-link '+ (activeMenu === 'Donee' ? 'active': '')} to="/admin/donee">Donee</Link>
                </li>
                <li className="nav-item">
                  <Link className={'nav-link '+ (activeMenu === 'Media' ? 'active': '')} to="/admin/media">Media</Link>
                </li>
              </ul>
              }
              {auth.role === 'donee' && 
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className={'nav-link '+ (activeMenu === 'Shipments' ? 'active': '')} to="/donee/shipments">Shipments</Link>
                </li>
                <li className="nav-item">
                  <Link className={'nav-link '+ (activeMenu === 'Media' ? 'active': '')} to="/donee/media">Media</Link>
                </li>
              </ul>
              }
            </div>
            <ul className="hdr_right d-flex">
              <li><Link to="/"><img src="/images/notification_icon.svg" alt='' /> <span className="noti_badge"></span></Link></li>
              <li className="has_child">
              <a onClick={handleRightDropdown}><img src="/images/user_icon.svg" alt='' /></a>
              <ul className="sub_menu" id="right_dropdown">
                <li><Link to="/logout"><img src="/images/logout_icon.svg" alt='' /> Logout</Link></li>
              </ul>
            </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
