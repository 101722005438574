import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import commonService from '../../../services/commonService';
import doneeService from '../../../services/doneeService';

import $ from 'jquery';
import {
	Dropdown,
	DropdownIndicator
} from '../../../Components/SelectDropdownCustom';
const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8}),
  menu: () => ({ overflowY: 'auto' }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: 6,
    opacity: '0.8',
    left: 6,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '30px'
  }),
};
const CreateDoneePopup = (props) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [isOpen, setIsOpen] =useState(false);
  const toggleOpen = () => {
    if(isOpen === true){
      setIsOpen(false);
    }else{
      setIsOpen(true);
    }
    
  };
  const handleClose = () => {
    props.handleCreateDoneeCancelCallback('');
    setShow(false);
  }
  const handleShow = () => {
    setValue('assignment_status', false);
    setShow(true);
  }
  const handleEditContact = () => setEditMode(true);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
        id: "",
        name: "",
        email: "",
        active_country: [],
        password: "",
        cpassword: "",
    },
  })
  const watchFormData = watch();
  const watchActiveCountry = watch('active_country');
  const onSubmit = (data) => {
    try {
      // Extract the 'country_id' values from each object using map()
      const user_contry_arr = data.active_country.map(item => item.value);
      const user_contry_str = user_contry_arr.join(',');

      var formData = new FormData();
      formData.append("name", data.name);
      formData.append("email", data.email);
      formData.append("country_id", user_contry_str);
      if(editMode === true){
        formData.append("type", 'details');
        formData.append("donee_id", data.id);
        doneeService.update(formData)
        .then(data => {
          console.log(data);
          var result_data = data;
          if(data.status === 'Ok'){
            props.handleCreateDoneeCallback(data);
            toast.success(result_data.message);
          } else{
            toast.error(result_data.message);
          }
        })
        .catch(error => console.error('Error fetching users:', error));
        //toast.success("Donee Updated Successfully.");
      }else{
        formData.append("password", data.password);
        doneeService.create(formData)
        .then(data => {
          var result_data = data;
          if(result_data.status === 'Ok'){
            props.handleCreateDoneeCallback(data);
            toast.success(result_data.message);
          } else{
            toast.error(result_data.message);
          }
        })
        .catch(error => console.error('Error fetching users:', error));
      }
        
        setShow(false);
      }
      catch(err){
          if(!err?.response){
              setError('no server response');
          }
          else {
              setError('update failed')
          }
      }
    
  }
  const customOptionsCountry = () => {
    return countryList.map(option => ({
      ...option,
      label_old:option.label, 
      label: (
        <div>
          <input
            type="checkbox"
            checked={selectedCountry.some(selectedOption => selectedOption.value === option.value)}
            onChange={() => handleChangeCountry(
              selectedCountry.some(selectedOption => selectedOption.value === option.value)
              ? selectedCountry.filter(selectedOption => selectedOption.value !== option.value)
              : [...selectedCountry, option]
            )}
          />
           <span> {option.label}</span>
        </div>
      )
    }));
  };
  const handleChangeCountry = (selectedOptions) => {
    setSelectedCountry(selectedOptions);
    setValue('active_country',selectedOptions);
  };
  const handleRemoveCountry = (optionToRemove) => {
    setSelectedCountry(selectedCountry.filter(option => option.value !== optionToRemove.value));
    setValue('active_country',selectedCountry.filter(option => option.value !== optionToRemove.value));
  };
  const handleSelectCountry = (data) =>{
    setSelectedCountry(data);
    setValue('active_country', data);
  }
  const customFilterOption = (option, inputValue) => {
    return option.data.label_old.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
  };
  useEffect(()=>{
    reset();
    if(props.showPopup === true){
      handleShow();
    } else {
      handleClose();
    }
    setData(props.doneeInfo);
    var contArr = [];
    commonService.getCountryList()
    .then(data => {
      data.data.forEach(row => {
        contArr.push({
          label: '+'+row.code+' '+row.name,
          value: row.id
        })
      })
      setCountryList(contArr);
    })
    .catch(error => console.error('Error fetching users:', error));
    
      
    if(props.doneeInfo && props.doneeInfo.id !== undefined){
      setEditMode(true);
      setValue('id', props.doneeInfo.id);
      setValue('email', props.doneeInfo.email);
      setValue('name', props.doneeInfo.first_name+' '+props.doneeInfo.last_name);
      //setValue('password', '');
      //setValue('cpassword', '');
      var selecCountry = [];
      props.doneeInfo.county_list.forEach((item) => {
        var arr_str = {
          label: '+'+item.code+' '+item.name,
          value: item.id,
          label_old: item.name
        }
        selecCountry.push(arr_str);
      })
      setSelectedCountry(selecCountry);
      setValue('active_country', selecCountry);
    }else{
      setEditMode(false);
      setValue('email', '');
      setValue('name', '');
      setValue('password', '');
      setValue('cpassword', '');
      setSelectedCountry([]);
    }
  },[props])
  useEffect(()=>{
    $(".form_group .form-control").focus(function(){
      $(this).parent().addClass('focus_label');
     })
     .blur(function(){
      var tmpval = $(this).val();
      if(tmpval === '') {
          $(this).parent().removeClass('focus_label');
      }
    })
  },[watchFormData]);
   return (
    <>
    <Modal className='modal modal_common fade' size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
        { !editMode && <Modal.Title>Create Donee</Modal.Title> }
        { editMode && <Modal.Title>Update Donee</Modal.Title> }
        </Modal.Header>
        <form className="form_common" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
            <div className="pop_cont_form">
                <div className="row">
                     <div className="col-md-6 mt-3 mb-2">
                        <div className={'form_group'+ (getValues('name') !== '' ? ' focus_label': '')}>
                          <label  className="form-label">Name <span className="req_star">*</span></label>
                          <input type="text" className={'form-control ' + (errors.name ? 'error' : ' ')} autoComplete='off' {...register("name", { required: true })} />
                          {errors.name && errors.name.type === "required" && (
                              <span className='input-error'>Please enter name.</span>
                          )}
                       </div>
                    </div>
                    <div className="col-md-6 mt-3 mb-2">
                      <div className={'form_group'+ (getValues('active_country') ? ' focus_label': '')}>
                        <label className="form-label">Active Countries <span className="req_star">*</span></label>
                        <Dropdown
                          isOpen={isOpen}
                          onClose={toggleOpen}
                          target={
                            <Button
                            className={'form-select basic-single single-custom-dropdown-multiple '+ ((errors.active_country && watchActiveCountry.length === 0) ? ' error': '')}
                              onClick={toggleOpen}
                              active={isOpen}
                            >
                              {selectedCountry ? `Select Active Countries` : 'Select Active Countries'} 
                                </Button>
                          }
                        >
                          <Select
                          className={'basic-single react-select-container react-select-container-absolute '+ ((errors.active_country && watchActiveCountry.length === 0) ? ' error': '')}
                            autoFocus
                            {...register("active_country", {required: true})}
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            isMulti
                            required
                            menuIsOpen
                            onChange={handleSelectCountry}
                            options={customOptionsCountry()}
                            filterOption={customFilterOption}
                            styles={selectStyles}
                            tabSelectsValue={false}
                            value={selectedCountry}
                          />
                        </Dropdown>
                        { !isOpen &&  <div className="mt-2 selected_tag_list"> {selectedCountry.map((item, i) => (
                                <span className='m-1' key={i}>{item.label_old} <a onClick={()=> handleRemoveCountry(item)} ><img src="/images/close_icon.svg" alt='' /></a></span>
                              ))}
                              </div>
                        }
                        {errors.active_country && watchActiveCountry.length === 0 && errors.active_country.type === "required" && (
                          <span className='input-error'>Select Active Countries.</span>
                        )}
                        </div>
                    </div>
                    <div className='col-md-12'>
                    <div className={'form_group'+ (getValues('email') !== '' ? ' focus_label': '')}>
                        <label className="form-label">Email ID <span className="req_star">*</span></label>
                        <input type="email" className={'form-control ' + (errors.email ? 'error' : ' ')} autoComplete='off'
                        {...register("email", { required: true, 
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please enter a valid email.",
                        }})} />
                        {errors.email && errors.email.type === "required" && (
                          <span className='input-error'>Enter Email ID to create Donee account.</span>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <span className='input-error'>{errors.email.message}</span>
                        )}
                      </div>
                    </div>
                    { !editMode && <div className="col-md-6 mt-2">
                      <div className={'form_group'+ (getValues('password') !== '' ? ' focus_label': '')}>
                        <label  className="form-label">Password <span className="req_star">*</span></label>
                        <input type="text" className={'form-control ' + (errors.password ? 'error' : ' ')} autoComplete='off' 
                        {...register("password", { 
                          required: !editMode,
                          pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message: "Invalid password"
                          }
                         })}/>
                        {errors.password && errors.password.type === 'required' && <span className='input-error'>Please enter password.</span>}
                        {errors.password && errors.password.type === 'pattern' && <span className='input-error'>Password must be 8 characters long with a mix of alphanumeric characters with one uppercase letter.</span>}
                      </div>
                    </div>
                    }
                    { !editMode && <div className="col-md-6 mt-2">
                      <div className={'form_group'+ (getValues('cpassword') !== '' ? ' focus_label': '')}>
                        <label  className="form-label">Confirm Password <span className="req_star">*</span></label>
                        <input type="text" className={'form-control ' + (errors.cpassword ? 'error' : ' ')} autoComplete='off' 
                        {...register("cpassword", 
                        {
                          required: !editMode,
                          message: "Test field required",
                          validate: (val) => {
                            if (editMode === false && watch('cpassword') === '') {
                              return "Please enter confirm password.";
                            }else if (editMode === false && watch('password') !== val) {
                              return "Confirm password must be same as password.";
                            }
                          },
                        })} />
                        {errors.cpassword?.message && <span className='input-error'>{errors.cpassword?.message}</span>}
                        {errors.cpassword && errors.cpassword.type === 'required' && <span className='input-error'>Please enter confirm password.</span>}
                      </div>
                    </div>
                    }
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn_cmn mdl_btn_cancel" onClick={handleClose}>Cancel</button>
            { !editMode && <button type="submit" className="btn btn_cmn mdl_btn_save">Add Donee</button>}
            { editMode && <button type="submit" className="btn btn_cmn mdl_btn_save">Update Donee</button>}
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default CreateDoneePopup
