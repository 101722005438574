import axiosInstance from '../api/axiosInstance';
const commonService = {
  getCountryList: async () => {
    try {
      const response = await axiosInstance.get('/country-list');
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
  getDoneeListByCountry: async (country_id) => {
    try {
      const response = await axiosInstance.get('/get-all-donee?country_id='+country_id);
      return response.data;
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  },
};

export default commonService;