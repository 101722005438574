import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import { ProvideAuth } from './Context/ProvideAuth';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
    <ProvideAuth>
      <Routes>
        <Route path ='/*' element={<App />}/>
     </Routes>
    </ProvideAuth>
    </Router>
);

