import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../Components/Header';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../../../Components/Footer';
import TableLoader from '../../../Components/TableLoader';
import CreateDoneePopup from './CreateDoneePopup';
import ChangePasswordPopup from './ChangePasswordPopup';
import { useContext } from "react";
import {AuthContext} from "../../../Context/ProvideAuth";
import axiosInstance from '../../../api/axiosInstance';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Badge, CloseButton } from 'react-bootstrap';

import $ from 'jquery';
import 'datatables.net';
import "datatables.net-dt/css/jquery.dataTables.css";

const AdminDonee = () => {
  const {auth, setAuth} = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [filterData, updateFilterData] = useState({
    country: [],
    donee: [],
    tag: [],
  });
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [viewDoneeRow, setViewDoneeRow] = useState([]);
  const [show, setShow] = useState(false);
  const [showCreateDonee, setShowCreateDonee] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const tableRef = useRef(null);
 
  const handleShipmentCancelFilter = (value) => {
    setShowFilter(false);
    
  };
  const handleCreateDoneeCallback = (value) => {
    setShowCreateDonee(false);
    $("#myTable1").DataTable().ajax.reload();
  };
  const handleChangePasswordCallback = (value) => {
    setShow(false);
  };
  const handleCreateDoneeCancelCallback = (value) => {
    setShowCreateDonee(false);
  };
  const handleChangePasswordCancelCallback = (value) => {
    setShowCreateDonee(false);
  };
  const removeFilterData = (index, type) => {
    if(type === 'country'){
      const filter_arr = filterData.country;
      filter_arr.splice(index, 1);
      updateFilterData({
        country: filter_arr,
        donee: filterData.donee,
        tag: filterData.tag,
      });
    }
    if(type === 'donee'){
      const filter_arr = filterData.donee;
      filter_arr.splice(index, 1);
      updateFilterData({
        country: filterData.country,
        donee: filter_arr,
        tag: filterData.tag,
      });
    }
    if(type === 'tag'){
      const filter_arr = filterData.tag;
      filter_arr.splice(index, 1);
      updateFilterData({
        country: filterData.country,
        donee: filterData.donee,
        tag: filter_arr,
      });
    }
  }
  const changePasswordPopupHandler = row => {
    if(row?.id){
      setViewDoneeRow(row);
      setShow(true);
      setShowFilter(false);
    }else{
      setShow(false);
    }
  }
  const editDoneePopupHandler = row => {
    if(row?.id){
      setViewDoneeRow(row);
      setShowCreateDonee(true);
      setShow(false);
    }else{
      setShow(false);
    }
  }
  const createDoneePopupHandler = () => {
      setViewDoneeRow([]);
      setShowCreateDonee(true);
      setShow(false);
  }
  useEffect(() => {
    // Initialize DataTable
    const dataTable = $(tableRef.current).DataTable({
      autoWidth: false,
      searching: false,
        destroy: true,
        processing: true,
        serverSide: true,
        "filter": true,
        lengthChange: true,
        scrollX: true,
        responsive: true,
        stateSave: true,
        paging: true,
        "lengthMenu": [ 10, 15, 50, 75, 100 ],
        regex: false,
        smart: false,
        ajax: {
        url: process.env.REACT_APP_API_URL+"/api/get-admin-users?user_type=donee",
        type: "GET",
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer '+auth.token);
        },
        data: function (data) {
          data['order'].forEach(function(items, index) {
            data['order'][index]['column'] = data['columns'][items.column]['data'];
          });
          data.sort_column = data.order[0].column;
          data.sort_order = data.order[0].dir;
          data.search_key = data.search.value;
          return data;
        },
        complete: function() {
            $('.row-delete').on('click', function() {
                //deleters($(this).attr('data-id'), 'blogs', 'delete');
            });
        }
    },
    language: {
      paginate: {
          previous: '<i class="fas fa-chevron-left"></i>',
          next: '<i class="fas fa-chevron-right"></i>'
      }
    },
    "columns": [ {
      "data": "first_name",
      "render": function ( data, type, row ) {
        var cont_str = row.first_name+' '+row.last_name;
        return '<div class="font_small">'+cont_str+'</div>';
      }
    },{
      "data": "email"
    },{
        "data": 'country'
    },{
      data: null,
      sortable: false,
      className: "center",
      defaultContent: '<a class="changePassword"><img src="/images/change-password.png" alt="" /></a>'
  }],
  columnDefs: [
    {
      targets: [0,1], // Index of the column you want to attach the click event to
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).on('click', () => {
          editDoneePopupHandler(rowData);
        });
      },
    },{
      "targets": 2,
      "render": function ( data, type, row ) {
        var cont_str = '';
        row.county_list.forEach((cont)=>{
          cont_str +=' <a class="t_border_btn">+'+cont.code+' '+cont.name+'</a> ';
        })
          return '<div class="font_small">'+cont_str+'</div>';
      }
    },{
      targets: [3], // Index of the column you want to attach the click event to
      createdCell: (td, cellData, rowData, row, col) => {
        $(td).find('.changePassword').on('click', () => {
          changePasswordPopupHandler(rowData);
        });
      },
    },
  ],
    order: [
      [0, "desc"]
    ]
      // DataTable options go here
    });
    $('.dataTables_length').insertAfter('.dataTables_info');
    $('.dataTables_filter select').addClass('form-select form-select-sm');
    $('.sorting .sort-icon').remove();
    dataTable.columns().iterator( 'column', function (ctx, idx) {
      $( dataTable.column(idx).header() ).append('<span class="sort-icon"/>');
    } );
    // Handle row click event
    // $(tableRef.current).on('click', 'tbody tr', function () {
    //   const rowData = dataTable.row(this).data();
    //   rowData.password = '********';
    //   rowData.country = '+1 USA';
    //   //changePasswordPopupHandler(rowData);
    //   // Perform actions with rowData as needed
    // });

    // Cleanup on component unmount
    return () => {
      $(tableRef.current).off('click', 'tbody tr');
      dataTable.destroy();
    };
  }, [filterData]);
  return (
    <>
    <Header activeMenu="Donee" />
    <main className="main-content">
    <section className="content_hdr_sec p-0">
        <div className="container-fluid">
          <div className="row mobile_2row justify-content-between align-items-center">
            <div className="col-md-auto">
            <ul className="nav_tabs_custom">
              <li className='active'><Link to="/admin/donee">All Donees</Link></li>
            </ul>
            </div>
            <div className="col-md-auto">
              <div className="cont_hdr_right">
                <div className="">
                  <button type='button' className='btn btn_cmn custom-btn-padding' size="sm" onClick={() => createDoneePopupHandler()}><img src="/images/add.svg" alt='' /> Create Donee</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid mb-3 custom-tags">
          {filterData.country.map(function(option, index) {
            return (
                <Badge bg="secondary" value={option.value} key={`country-key-`+option.value}>{option.label} <CloseButton onClick={(e) => removeFilterData(index, 'country')} className='btn-sm' /></Badge>
                )
            })}
            {filterData.donee.map(function(option, index) {
            return (
                <Badge bg="secondary" value={option.value} key={`donee-key-`+option.value}>{option.label} <CloseButton onClick={(e) => removeFilterData(index, 'donee')} /></Badge>
                )
            })}
            {filterData.tag.map(function(option, index) {
            return (
                <Badge bg="secondary" value={option.value} key={`tag-key-`+option.value}>{option.label} <CloseButton onClick={(e) => removeFilterData(index, 'tag')} /></Badge>
                )
            })}
        </div>
        <section className="data_table_main">
        <div className="container-fluid">
          <div className="data_table_wrap datatable-custom-design row-hover">
              <table id="myTable1" ref={tableRef} className="table table-borderless datatable_cmn" >
                <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email ID</th>
                      <th>Active Countries</th>
                      <th>Change Password</th>
                    </tr>
                </thead>
                <tbody>
                 </tbody>
              </table>
          </div>
        </div>
      </section>  
    </main>
    <Footer />
    <ChangePasswordPopup handleChangePasswordCancelCallback={handleChangePasswordCancelCallback} handleChangePasswordCallback={handleChangePasswordCallback} doneeInfo={viewDoneeRow} showPopup={show} />
    <CreateDoneePopup handleCreateDoneeCancelCallback={handleCreateDoneeCancelCallback} handleCreateDoneeCallback={handleCreateDoneeCallback} doneeInfo={viewDoneeRow} showPopup={showCreateDonee} />
    </>
  )
}

export default AdminDonee
