import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form"
import Footer from '../../../Components/Footer';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

const AdminViewCompletedShipments = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState('');
  const [filterData, updateFilterData] = useState({
    country: [],
    donee: [],
    tag: [],
  });
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [tagList, setTagList] = useState([]);
  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => {
    setShow(true);
  }
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
        id: "",
        shipment_eta: "",
        notes: "",
        tag: "",
        sent_blank_dis_report: '',
        recevied_completed_dis_report: '',
        mark_as_complete: false,
        pro_farma_invoice: []
    },
  })
  
  const fetchUsers = async page => {
    setLoading(true);
    const response = await axios.get(`https://reqres.in/api/user/1`);
    //setData(response.data.data);
    var demodata = response.data.data
    demodata.shipment = '#61234';
    demodata.contact = 'Leo Thomas <br> welcome@xyz.com';
    demodata.ship_date = '12 Oct 2023';
    demodata.shipment_eta = '12 Oct 2023';
    demodata.country = '+1 USA';
    demodata.donee = 'Donee 1';
    demodata.assign_contact = 'aa';
      demodata.mark_as_completed = 'aa';
    setData(demodata);
    setLoading(false);
  };
  const goBack = () =>{
    navigate('/admin/shipments/active',{state:{pageDataBack: location.state?.pageData}});
  }
  const onSubmit = (data) => {
    console.log(data);
    toast.success("Shipment Details Updated.");
    setTimeout(function(){
      goBack();
    },2000);
  }
  const handleUploadedFile = (event) => {
    const file = event.target.files[0];
    //console.log(file);
    //const urlImage = URL.createObjectURL(file);
    setPreview(file.name);
  };
  const handleSelectTag = (data) =>{
    setSelectedTag(data);
    setValue('tag',data);
  }
  useEffect(() => {
    fetchUsers(1); // fetch page 1 of users
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var tagArr = [
      {value: 1, label: "Tag 1"},
      {value: 2, label: "Tag 2"},
      {value: 3, label: "Tag 3"},
      {value: 4, label: "Tag 4"},
      {value: 5, label: "Tag 5"},
      {value: 6, label: "Tag 6"},
      {value: 7, label: "Tag 7"},
    ]
    setTagList(tagArr);
  }, []);
  useEffect(()=>{
    document.body.classList.add('bg_light');
    document.body.classList.add('body_admin');
  },[])
  return (
    <>
    <main className="main-content">
      <section className="content_hdr_sec">
        <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
                <h1 className="page_head"><Link className="back_link" to="/admin/shipments/completed" state={{
    pageDataBack: location.state?.pageData,
  }}><img src="/images/back_arrow2.svg" alt=""/></Link> View Shipment</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="view_shipment_sec">
        <div className="container-fluid">
          <div className="view_shipment_main">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="view_tab1" role="tabpanel" aria-labelledby="view_tab1-tab">
                <div className="view_shipment_body">
                  <form className="form_normal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" noValidate>
                    <div className="view_ship_cont">
                      <div className="row">
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Shipment ID <span className="req_star">*</span></label>
                              <input type="text"  readOnly className="form-control" value="#61234" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Ship Date <span className="req_star">*</span></label>
                              <input type="text" readOnly className="form-control" value="12 Oct 2023" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Country <span className="req_star">*</span></label>
                              <input type="text" readOnly className="form-control" value="USA" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Shipment ETA </label>
                                <input type="text" readOnly className="form-control" {...register("shipment_eta", {required: false})} value="12 Oct 2023"/>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Donee <span className="req_star">*</span></label>
                              <input type="text" readOnly className="form-control" value="Lorem Ipsum" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Contact Email ID <span className="req_star">*</span></label>
                              <input type="email" readOnly className="form-control" value="welcome@xyz.com" />
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Tags <span className="req_star">*</span></label>
                              <div className="selected_tag_list">
                                <span>Tag 1</span> <span>Tag 2</span>
                              </div>
                            </div>
                            
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Notes</label>
                              <textarea className="form-control" {...register("notes", {required: false})} placeholder="Enter your note..."></textarea>
                          </div>
                        </div>
                        <div className='row'> 
                          <div className="col-md-6 mb-3">
                              <label className="form-label d-block">Sent Blank Distribution Report</label>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("sent_blank_dis_report")} className="form-check-input" type="radio"  value="yes" /><span className="radio_cir"></span> Yes</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("sent_blank_dis_report")} className="form-check-input" type="radio" value="no" /><span className="radio_cir"></span> No</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("sent_blank_dis_report")} className="form-check-input" type="radio" value="na" /><span className="radio_cir"></span> N/A</label>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Acknowledgement of Delivery</label>
                              <div className="selected_tag_list">
                                <span className="mt-0">Completed</span>
                              </div>
                          </div>
                        </div>
                        <div className='row'> 
                          <div className="col-md-6 mb-3">
                              <label className="form-label d-block">Received Completed Distribution Report</label>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("recevied_completed_dis_report")} className="form-check-input" type="radio" value="yes" /><span className="radio_cir"></span> Yes</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("recevied_completed_dis_report")} className="form-check-input" type="radio" value="no" /><span className="radio_cir"></span> No</label>
                              </div>
                              <div className="form-check form-check-inline radio_custom">
                                <label className="form-check-label"><input {...register("recevied_completed_dis_report")} className="form-check-input" type="radio" value="na" /><span className="radio_cir"></span> N/A</label>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Confirmation of Goods</label>
                              <div className="selected_tag_list">
                                <span className="mt-0">Completed</span>
                              </div>
                          </div>
                        </div>
                        <div className='row'> 
                          <div className="col-md-6 mb-3">
                            <div className="input_file_custom">
                              <div className="inputtype_file_btn"><Link><img src="/images/download_icon.svg" alt="" /> Download Delivery Receipt</Link></div> 
                            </div>
                            <div className="input_file_custom ml-2" style={{marginLeft:'20px'}}>
                              <div className="inputtype_file_btn"><Link><img src="/images/download_icon.svg" alt="" />  Download Document</Link></div>
                            </div>
                            <p className="file_name">{preview}</p>
                          </div> 
                      </div>
                  </div>
                  <div className="view_ship_ftr text-end">
                    <Link to="/admin/shipments/completed" state={{
                        pageDataBack: location.state?.pageData}} className="back_link btn btn_cmn mdl_btn_cancel">Cancel</Link>
                    <button type="submit" className="btn btn_cmn mdl_btn_save">Save Changes</button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
    </>
  )
}

export default AdminViewCompletedShipments
