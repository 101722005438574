import React from 'react'
import { defaultTheme } from 'react-select';
const { colors } = defaultTheme;

// styled components
const Menu = (props) => {
	const shadow = 'hsla(218, 50%, 10%, 0.1)';
	return (
	  <div
		css={{
		  backgroundColor: 'white',
		  borderRadius: 4,
		  boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
		  marginTop: 8,
		  position: 'absolute',
		  zIndex: 2,
		}}
		{...props}
	  />
	);
  };
  const Blanket = (props) => (
	<div
	  css={{
		bottom: 0,
		left: 0,
		top: 0,
		right: 0,
		position: 'fixed',
		zIndex: 1,
	  }}
	  {...props}
	/>
  );
  const Dropdown = ({ children, isOpen, target, onClose }) => (
	<div css={{ position: 'relative' }}>
	  {target}
	  {isOpen ? <Menu>{children}</Menu> : null}
	  {isOpen ? <Blanket onClick={onClose} /> : null}
	</div>
  );
  const Svg = (p) => (
	<svg
	  width="24"
	  height="24"
	  viewBox="0 0 24 24"
	  focusable="false"
	  role="presentation"
	  {...p}
	/>
  );
  const DropdownIndicator = () => (
	<div css={{ color: colors.neutral20, height: 24, width: 32 }}>
	  <Svg>
		<path
		  d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
		  fill="currentColor"
		  fillRule="evenodd"
		/>
	  </Svg>
	</div>
  );
  export {
	Menu,
	Blanket,
	Dropdown,
	Svg,
	DropdownIndicator
  } ;


