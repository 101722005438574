import { useNavigate, useLocation } from "react-router";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import axiosInstance from '../api/axiosInstance';
import { useForm } from "react-hook-form"
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
  
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/admin/shipments"
  const { setAuth} = useAuth()
  const [error, setError] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password'); 
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: ""
    },
  })
  const outerDivRef = useRef(null);
  const watchUsername = watch('username');
  const watchPassword = watch('password');
  
const onSubmit = async (data) => {
      try {
        var form = new FormData();
        form.append("email", data.username);
        form.append("password", data.password);
        const res = await axiosInstance.post('/sign-in', form)
        .then(res => {
          var result_data = res.data;
          console.log(res.data);
          if(result_data?.status === 'Ok'){
            setAuth(
              {
                "user_id":result_data.data.id,
                "role":result_data.data.user_type,
                "name":result_data.data.first_name+' '+result_data.data.last_name,
                "first_name":result_data.data.first_name,
                "last_name":result_data.data.last_name,
                "phone_number":result_data.data.phone_number,
                "country":result_data.data.country,
                "token":result_data.data.token
              });
            navigate('/');
          }
          else{
            if(result_data.message === 'Enter email ID is not recognized'){
              setErrorEmail("Entered email ID doesn't match.");
            } else if (result_data.message === 'Invalid login credentials'){
              setErrorPassword("Entered password is incorrect.");
            }else {
              toast.error(result_data.message);
            }
          }
        })
        /*if(data.username === 'donee@admin.com'){
          setAuth({"user_id":1, "role":`donee`, "name":`Sachin`, "token":`token1234`});
        }else{
          setAuth({"user_id":1, "role":`admin`, "name":`Sachin`, "token":`token1234`});
        }*/
        
        //navigate('/');
      }
      catch(err){
          if(!err?.response){
            toast.error('no server response');
          }
          else {
            toast.error('Login failed');
          }
      }
}
const changePasswordField = () => {
  if(passwordFieldType === 'password'){
    setPasswordFieldType('text');
  }else{
    setPasswordFieldType('password');
  }
}
 useEffect(()=>{
  $(".form_group .form-control").focus(function(){
    $(this).parent().addClass('focus_label');
   })
   .blur(function(){
    var tmpval = $(this).val();
    if(tmpval == '') {
        $(this).parent().removeClass('focus_label');
    }
  })
  if(watchUsername !== ''){
    setErrorEmail('');
    $('#username_input').addClass('focus_label');
  }else{
    $('#username_input').removeClass('focus_label');
  }
  if(watchPassword !== ''){
    setErrorPassword('');
    $('#password_input').addClass('focus_label');
  }else{
    $('#password_input').removeClass('focus_label');
  }
 }, [watchUsername, watchPassword])

  return (                                       
    <main className="main-content main_vert_center">
      <div className="login_flow_wrap">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <div className="loginflow_right" ref={outerDivRef}>
              <div className="loginflow_logo">
                <img src="images/logo.svg" alt="logo" />
              </div>
              <h1>Sign In</h1>
              <form className="form_common" onSubmit={handleSubmit(onSubmit)} autoComplete="new" noValidate>
                <div className="form_group" id="username_input">
                  <label className="form-label">Email ID <span className="req_star">*</span></label>
                  <input type="email" className={'form-control ' + (errors.username || errorEmail ? 'error' : ' ')} autoComplete='off'
                  {...register("username", { required: true, 
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please enter a valid email.",
                  }})} />
                  {errors.username && errors.username.type === "required" && (
                    <span className='input-error'>Please enter email.</span>
                  )}
                  {errors.username && errors.username.type === "pattern" && (
                    <span className='input-error'>{errors.username.message}</span>
                  )}
                  {errorEmail && <span className='input-error'>{errorEmail}</span>}
                </div>
                <div className="form_group mb-2" id="password_input">
                  <label className="form-label">Password <span className="req_star">*</span></label>
                  <input type={passwordFieldType} className={'form-control ' + (errors.password || errorPassword ? 'error' : ' ')} autoComplete='off' {...register("password", { required: true })} />
                  <i className={'pass_vis_icon ' + (passwordFieldType === 'password' ? '' : 'on')} id="verifyPassword" onClick={changePasswordField}> 
                    <img className="iconeye" src="images/icon-eye-slash.svg" alt="" /> 
                    <img className="iconeyeslash" src="images/icon-eye.svg" alt="" />
                  </i>
                  {errors.password && <span className='input-error'>Please enter password.</span>}
                  {errorPassword && <span className='input-error'>{errorPassword}</span>}
                </div>
                <div className="form_group">
                  <div className="row justify-content-between">
                    <div className="col-auto">
                      <label className="form-check-label check_custom">
                        <input type="checkbox" className="form-check-input"/>
                        <span className="check_squire"><img src="images/check_small.svg" width="12" height="12" alt="" /></span>
                        Remember me
                      </label>
                    </div>
                    <div className="col-auto">
                      <Link to="/forgot-password" className="form_link">Forgot Password?</Link>
                    </div>
                  </div>
                </div>
                <div className="frm_btn_full">
                  <button type="submit" className="btn btn_cmn">Sign in</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="loginflow_left">
              <figure><img src="images/login_flow_left_img.jpg" alt="Login" /></figure>
              <div className="loginflow_left_cap">
                <h4>Lorem ipsum dolor sit amet consectetur. Nulla elementum </h4>
                <p className="mb-0">Lorem ipsum dolor sit amet consectetur. Pellentesque id vel nisl volutpat maecenas. Vitae nulla viverra dolor eget interdum massa ac sagittis.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnFocusLoss={false}
        pauseOnHover
        theme="colored"
        />
    </main>
  )
}

export default Login
