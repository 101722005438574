import React, { useEffect, useRef, useState } from 'react'
import Header from '../../../Components/Header';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Footer from '../../../Components/Footer';
import Modal from 'react-bootstrap/Modal';
import TableLoader from '../../../Components/TableLoader';
import { toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, CloseButton } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import $ from 'jquery';
import 'datatables.net';
import "datatables.net-dt/css/jquery.dataTables.css";

const schema = yup.object().shape({
  file: yup
    .mixed()
    .test('required', "Please upload image or video.", (value) =>{
      return value && value.length
    } )
    .test('fileType', 'File format is not supported. Please upload an image (jpg, jpeg, png) or a video (mp4, webm).', (value) => {
      console.log('value sss',value);
      if (!value || value[0] === undefined) return true; // Empty value is valid
      const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/webm']; // Add more formats as needed
      return supportedFormats.includes(value[0].type);
    }),
});

const DoneeMedia = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [preview, setPreview] = useState('');
  const [fileSizeKB, setFileSizeKB] = useState(0);
  const [mediaType, setMediaType] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState('');
  const [selectedFile, setSelectedFile] = useState()
  const [filterData, updateFilterData] = useState({
    country: [],
    donee: [],
    tag: [],
  });
  const [showDelete, setShowDelete] = useState(false);
  const tableRef = useRef(null);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        description: ''
    },
  })
  const handleCloseDelete = () => {
    setShowDelete(false);
  }
  const onSubmit = (data) => {
    console.log(data);
    toast.success("Media Upload Successfully!");
    handleUploadedClear();
  }
  const handleUploadedFile = (event) => {
    const file = event.target.files[0];
    //console.log(file);
    //const urlImage = URL.createObjectURL(file);
    setSelectedFile(file);
  };
  const handleUploadedClear = () => {
    setPreview(null);
    setSelectedFile(null);
    setFileName('');
    setMediaType('');
    setValue('file', null);
    setFileError('');
  };
  const validateFile = (file) => {
    schema.validate({ file })
      .then(() => {
        // File is valid
        setFileError('');
        if (file) {
          const files = file[0];
          const reader = new FileReader();
          reader.onload = () => {
            setPreview(reader.result);
            setSelectedFile(files);
            const fileSizeInKB = Math.round(files.size / 1024);
            setFileSizeKB(fileSizeInKB);
            const fileType = files.type.split('/')[0];
            setMediaType(fileType);
            setFileName(files.name);
          };
          reader.readAsDataURL(files);
        }
      })
      .catch((err) => {
        // File is invalid
        if(err && err.errors){
          setPreview(null);
          setFileError(err.errors[0]);
        }
      });
  };
  const onSelectFile = e => {
      const file = e.target.files;
      validateFile(file);
  }
  useEffect(() => {
    // Initialize DataTable
    
  }, [filterData]);
  return (
    <>
    <Header activeMenu="Media" />
    <main className="main-content">
      <section className="view_shipment_sec mt-4">
        <div className="container-fluid">
          <div className="view_shipment_main">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active median-nav-link" id="view_tab1-tab" data-bs-toggle="tab" data-bs-target="#view_tab1" type="button" role="tab" aria-controls="view_tab1" aria-selected="true">Upload Media</button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="view_tab1" role="tabpanel" aria-labelledby="view_tab1-tab">
                <div className="view_shipment_body">
                  <form className="form_normal" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" noValidate>
                    <div className="view_ship_cont">
                      <div className="row">
                          <div className="col-md-6 mb-3">
                          <div className={'input_file_custom_media'+ (fileName && !fileError ? ' has-files': '')}>
                          <label className="form-label">Photo/Video <span className="req_star">*</span></label>
                              <input id="file" type="file" {...register("file")} onChange={onSelectFile} />
                              <div className="inputtype_file_btn"><img src="/images/gallery-export.svg" alt="" /> <div><Link>Click here</Link> to upload a image/video.</div></div>
                            </div>
                            {!fileError && preview &&  <div className="preview_image">
                              <div className='preview_thumb'>
                              {mediaType === 'image' && (<img src={preview} /> )}
                              {mediaType === 'video' && (
                                <video controls style={{ maxWidth: '100%' }}>
                                  <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                                  Your browser does not support the video tag.
                                </video>
                              )}

                              </div>
                             {fileName && <div className='preview_text'>
                                  <p>{fileName}</p>
                                  <p>{fileSizeKB} KB</p>
                              </div>
                              }
                              <div className='preview_close'>
                                  <i onClick={handleUploadedClear} className='fa fa-times'></i>
                              </div>
                              </div> } 
                              {errors.file && errors.file.type === 'required' && fileError ==='' && !preview && <span className='input-error'>Please upload image or video.</span>}
                              {fileError && <span className='input-error'>{fileError}</span>}
                          </div>
                          <div className="col-md-6 mb-3">
                              <label className="form-label">Description</label>
                              <textarea style={{minHeight: '212px'}} className="form-control" {...register("description", {required: false})} placeholder="Enter description here..."></textarea>
                          </div>
                      </div>
                  </div>
                  <div className="view_ship_ftr text-end">
                    <Link to="/admin/shipments/active" state={{
                        pageDataBack: location.state?.pageData}} className="back_link btn btn_cmn mdl_btn_cancel">Cancel</Link>
                    <button type="submit" className="btn btn_cmn mdl_btn_save">Upload Media</button>
                  </div>
                  </form>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    </main>
    <Footer />
    </>
  )
}

export default DoneeMedia
