import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form"
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { countryArr } from '../../../Constants/GlobalDataArr';
import Button from 'react-bootstrap/Button';
import {
	Dropdown,
	DropdownIndicator
} from '../../../Components/SelectDropdownCustom';
const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8}),
  menu: () => ({ maxHeight: 200, overflowY: 'auto' }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: 6,
    opacity: '0.8',
    left: 6,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    paddingLeft: '30px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = '#000';
    return {
      ...styles,
      backgroundColor: '#FFF',
      color: '#000',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#FFF'
      },

    }
  }
};
const ShipmentFilterPopup = (props) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedDonee, setSelectedDonee] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [doneeList, setDoneeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isOpen, setIsOpen] =useState(false);
  const toggleOpen = () => {
    if(isOpen === true){
      setIsOpen(false);
    }else{
      setIsOpen(true);
    }
    
  };
  const handleClose = () => {
    setShow(false);
    props.handleShipmentCancelFilter('');
  }
  const handleShow = () => setShow(true);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
        donee: [],
        tag: [],
        country: [],
    },
  })
  const onSubmit = (data) => {
    data.donee.forEach((item)=>{
      item.label =item.label_old;
    })
    data.country.forEach((item)=>{
      item.label =item.label_old;
    })
    data.tag.forEach((item)=>{
      item.label =item.label_old;
    })
    props.handleShipmentFilter(data);
    handleClose();
  }
  const handleSelectTag = (data) =>{
    setSelectedTag(data);
    setValue('tag',data);
  }
  const handleSelectCountry = (data) =>{
    setSelectedCountry(data);
    setValue('country',data);
  }
  const handleSelectDonee = (data) =>{
    setSelectedDonee(data);
    setValue('donee',data);
  }
  const handleClearFilter = () => {
    setSelectedDonee([]);
    setSelectedCountry([]);
    setSelectedTag([]);
    setValue('country',[]);
    setValue('donee',[]);
    setValue('tag',[]);
  }
  const handleChange = (selectedOptions) => {
    setSelectedDonee(selectedOptions);
    setValue('donee',selectedOptions);
  };
  const handleChangeCountry = (selectedOptions) => {
    setSelectedCountry(selectedOptions);
    setValue('country',selectedOptions);
  };
  const handleChangeTag = (selectedOptions) => {
    setSelectedTag(selectedOptions);
    setValue('tag',selectedOptions);
  };
  const handleRemoveDonee = (optionToRemove) => {
    setSelectedDonee(selectedDonee.filter(option => option.value !== optionToRemove.value));
    setValue('donee',selectedDonee.filter(option => option.value !== optionToRemove.value));
  };
  const handleRemoveCountry = (optionToRemove) => {
    setSelectedCountry(selectedCountry.filter(option => option.value !== optionToRemove.value));
    setValue('country',selectedCountry.filter(option => option.value !== optionToRemove.value));
  };
  const handleRemoveTag = (optionToRemove) => {
    setSelectedTag(selectedTag.filter(option => option.value !== optionToRemove.value));
    setValue('tag',selectedTag.filter(option => option.value !== optionToRemove.value));
  };
  const customFilterOption = (option, inputValue) => {
    return option.data.label_old.toString().toLowerCase().includes(inputValue.toString().toLowerCase());
  };

  const customOptionsDonee = () => {
    return doneeList.map(option => ({
      ...option,
      label_old:option.label, 
      label: (
        <div>
          <input
            type="checkbox"
            className='form-check-input'
            checked={selectedDonee.some(selectedOption => selectedOption.value === option.value)}
            onChange={() => handleChange(
              selectedDonee.some(selectedOption => selectedOption.value === option.value)
              ? selectedDonee.filter(selectedOption => selectedOption.value !== option.value)
              : [...selectedDonee, option]
            )}
          />
           <span className='select-checkbox-label'> {option.label}</span>
        </div>
      )
    }));
  };
  const customOptionsCountry = () => {
    return countryList.map(option => ({
      ...option,
      label_old:option.label, 
      label: (
        <div>
          <input
            type="checkbox"
            className='form-check-input'
            checked={selectedCountry.some(selectedOption => selectedOption.value === option.value)}
            onChange={() => handleChangeCountry(
              selectedCountry.some(selectedOption => selectedOption.value === option.value)
              ? selectedCountry.filter(selectedOption => selectedOption.value !== option.value)
              : [...selectedCountry, option]
            )}
          />
           <span className='select-checkbox-label'> {option.label}</span>
        </div>
      )
    }));
  };
  const customOptionsTags = () => {
    return tagList.map(option => ({
      ...option,
      label_old:option.label, 
      label: (
        <div>
          <input
            type="checkbox"
            className='form-check-input'
            checked={selectedTag.some(selectedOption => selectedOption.value === option.value)}
            onChange={() => handleChangeTag(
              selectedTag.some(selectedOption => selectedOption.value === option.value)
              ? selectedTag.filter(selectedOption => selectedOption.value !== option.value)
              : [...selectedTag, option]
            )}
          />
           <span className='select-checkbox-label'> {option.label}</span>
        </div>
      )
    }));
  };
  useEffect(()=>{
    if(props.showPopup === true){
      handleShow();
    } else {
      handleClose();
    }
    setData(props.contactInfo);
    var doneeArr = [
        {value: 1, label: "Donee 1", className: 'custom-class-1'},
        {value: 2, label: "Donee 2", className: 'custom-class-1'},
        {value: 3, label: "Donee 3", className: 'custom-class-1'},
        {value: 4, label: "Donee 4", className: 'custom-class-1'},
        {value: 5, label: "Donee 5", className: 'custom-class-1'},
        {value: 6, label: "Donee 6", className: 'custom-class-1'},
        {value: 7, label: "Donee 7", className: 'custom-class-1'},
    ]
    setDoneeList(doneeArr);
    var tagArr = [
      {value: 1, label: "Tag 1"},
      {value: 2, label: "Tag 2"},
      {value: 3, label: "Tag 3"},
      {value: 4, label: "Tag 4"},
      {value: 5, label: "Tag 5"},
      {value: 6, label: "Tag 6"},
      {value: 7, label: "Tag 7"},
    ]
    setTagList(tagArr);
    setCountryList(countryArr);
    setSelectedDonee(props.filterData.donee);
    setSelectedCountry(props.filterData.country);
    setSelectedTag(props.filterData.tag);
    setValue('country',props.filterData.country);
    setValue('donee',props.filterData.donee);
    setValue('tag',props.filterData.tag);
  },[props])
  return (
    <>
    <Modal className='modal modal_medium fade modal_common' size="sm" show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
          <Modal.Title>Filter</Modal.Title>
          <button type="button" onClick={handleClearFilter} className="btn btn_clear"> Clear All</button>
        </Modal.Header>
        <form className="form_normal" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
            <div className="pop_filter_tab">
              <div className="d-flex align-items-start">
                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="v-pills-1-tab" data-bs-toggle="pill" data-bs-target="#v-pills-1" type="button" role="tab" aria-controls="v-pills-1" aria-selected="true">Donee</button>
                  <button className="nav-link" id="v-pills-2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-2" type="button" role="tab" aria-controls="v-pills-2" aria-selected="false">Country</button>
                  <button className="nav-link" id="v-pills-3-tab" data-bs-toggle="pill" data-bs-target="#v-pills-3" type="button" role="tab" aria-controls="v-pills-3" aria-selected="false">Tag</button>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-1-tab">
                  <Dropdown
                          isOpen={isOpen}
                          onClose={toggleOpen}
                          target={
                            <Button
                            className='form-select basic-single single-custom-dropdown-multiple'
                              onClick={toggleOpen}
                              active={isOpen}
                            >
                              {selectedDonee ? `Select Donee` : 'Select Donee'} 
                                </Button>
                          }
                        >
                          <Select
                          className={'basic-single react-select-container'}
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            isMulti
                            menuIsOpen
                            styles={selectStyles}
                            onChange={handleSelectDonee}
                            options={customOptionsDonee()}
                            filterOption={customFilterOption}
                            placeholder="Search"
                            tabSelectsValue={false}
                            value={selectedDonee}
                          />
                        </Dropdown>
                      { !isOpen && <div className="mt-2 dropdonw-selected-tags"> {selectedDonee.map((item, i) => (
                          <span className="custom-tag" key={i}>
                            <a className="t_border_btn">{item.label} <i onClick={()=> handleRemoveDonee(item)} className="fa fa-times"></i></a>
                          </span>
                          ))}
                        </div>
                      }
                    {/*<Select className="basic-single"
                        classNamePrefix="select"
                        //isDisabled={isDisabled}
                        isClearable={false}
                        onChange={handleSelectDonee}
                        name="donee"
                        isMulti
                        isSearchable={true}
                        value={selectedDonee}
                        placeholder="Select Donee"
                        options={doneeList} />*/}
                  </div>
                  <div className="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-2-tab">
                  <Dropdown
                          isOpen={isOpen}
                          onClose={toggleOpen}
                          target={
                            <Button
                            className='form-select basic-single single-custom-dropdown-multiple'
                              onClick={toggleOpen}
                              active={isOpen}
                            >
                              {selectedCountry ? `Select Country` : 'Select Country'} 
                                </Button>
                          }
                        >
                          <Select
                          className={'basic-single react-select-container'}
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            isMulti
                            menuIsOpen
                            styles={selectStyles}
                            onChange={handleSelectCountry}
                            options={customOptionsCountry()}
                            filterOption={customFilterOption}
                            placeholder="Search"
                            tabSelectsValue={false}
                            value={selectedCountry}
                          />
                        </Dropdown>
                        { !isOpen &&  <div className="mt-2 dropdonw-selected-tags"> {selectedCountry.map((item, i) => (
                          <span className="custom-tag" key={i}>
                            <a className="t_border_btn">{item.label} <i onClick={()=> handleRemoveCountry(item)} className="fa fa-times"></i></a>
                          </span>
                          ))}
                        </div> 
                        }
                  {/*<Select className="basic-single"
                        classNamePrefix="select"
                        //isDisabled={isDisabled}
                        isClearable={false}
                        name="country"
                        onChange={handleSelectCountry}
                        isMulti
                        isSearchable={true}
                        value={selectedCountry}
                        placeholder="Select Country"
                       options={countryList} />*/}
                  </div>
                  <div className="tab-pane fade" id="v-pills-3" role="tabpanel" aria-labelledby="v-pills-3-tab">
                  <Dropdown
                          isOpen={isOpen}
                          onClose={toggleOpen}
                          target={
                            <Button
                            className='form-select basic-single single-custom-dropdown-multiple'
                              onClick={toggleOpen}
                              active={isOpen}
                            >
                              {selectedTag ? `Select Tag` : 'Select Tag'} 
                                </Button>
                          }
                        >
                          <Select
                          className={'basic-single react-select-container'}
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            isMulti
                            menuIsOpen
                            styles={selectStyles}
                            onChange={handleSelectTag}
                            options={customOptionsTags()}
                            filterOption={customFilterOption}
                            placeholder="Search"
                            tabSelectsValue={false}
                            value={selectedTag}
                          />
                        </Dropdown>
                        { !isOpen &&  <div className="mt-2 dropdonw-selected-tags"> {selectedTag.map((item, i) => (
                          <span className="custom-tag" key={i}>
                            <a className="t_border_btn">{item.label} <i onClick={()=> handleRemoveTag(item)} className="fa fa-times"></i></a>
                          </span>
                          ))}
                        </div>
                        }
                  {/*<Select className="basic-single"
                        classNamePrefix="select"
                        //isDisabled={isDisabled}
                        isClearable={false}
                        onChange={handleSelectTag}
                        name="tag"
                        isMulti
                        isSearchable={true}
                        value={selectedTag}
                        placeholder="Select Tag"
                        options={tagList} />*/}
                  </div>
                </div>
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn_cmn mdl_btn_cancel" onClick={handleClose}>Cancel</button>
            <button type="submit" className="btn btn_cmn mdl_btn_save">Apply Filters</button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ShipmentFilterPopup
